import React, { useRef, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Contexts/MyContext";
import { Spinner, Row, Col } from "react-bootstrap";

const Header = () => {
  const [navbarColor, setNavbarColor] = useState("transparent");
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const { user, authContext } = useAuth();
  const headerRef = useRef(null);
  const prevScrollPos = useRef(window.scrollY);
  const [width, setWidth] = useState(window.innerWidth);

  const storedValue = () => {
    return authContext;
  };
  const [expanded, setExpanded] = useState(false);
  const handleBrandClick = () => {
    if (window.innerWidth < 992) {
      setExpanded(false);
    }
  };

  const handleNavClick = () => {
    if (window.innerWidth < 992) {
      setExpanded(!expanded);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (window.scrollY > 0.8 * window.innerHeight) {
        setNavbarColor("#000000");
      } else {
        setNavbarColor("transparent");
      }
      if (
        currentScrollPos - prevScrollPos.current > 20 &&
        currentScrollPos > 100
      ) {
        setExpanded(false);
      }
      prevScrollPos.current = currentScrollPos;
    };
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (authContext && !user.userInfo) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Navbar
      ref={headerRef}
      expanded={expanded}
      expand="lg"
      variant="dark"
      className={
        isHomePage
          ? "navbar-home sticky-top px-3"
          : "navbar-others sticky-top px-3 bg-black"
      }
      style={{
        backgroundColor: navbarColor,
        transition: "background-color 0.3s",
      }}
    >
      <Navbar.Brand>
        <Link to="/" onClick={handleBrandClick}>
          <img
            src="/FG_Logo.webp"
            className="d-block w-10"
            width={80}
            height={80}
            alt="1"
          />
        </Link>
      </Navbar.Brand>
      <div className="d-flex ms-auto">
        {width < 992 ? (
          <Row className="d-flex align-items-center">
            {" "}
            {/* Ensures horizontal alignment and centers vertically */}
            <Col className="d-flex justify-content-center">
              {" "}
              {/* Centers the icon */}
              <Nav.Link
                as={Link}
                to="/login"
                eventKey="6"
                aria-label="My Profile"
                className="icon-small d-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon
                  icon={faUser}
                  size="lg"
                  onClick={handleBrandClick}
                  className={storedValue() ? "text-danger" : "text-light"}
                  aria-label="My Profile"
                />
              </Nav.Link>
            </Col>
            <Col className="d-flex justify-content-center">
              {" "}
              {/* Centers the cart icon */}
              <Nav.Link
                as={Link}
                to="/cart"
                eventKey="7"
                aria-label="My Cart"
                className="icon-small d-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  size="lg"
                  onClick={handleBrandClick}
                  className={storedValue() ? "text-danger" : "text-light"}
                  aria-label="My Cart"
                />
                <span className={storedValue() ? "text-danger" : "text-light"}>
                  {user?.cartInfo?.length > 0
                    ? `(${user?.cartInfo.reduce(
                        (total, item) => total + item.qty,
                        0
                      )})`
                    : ""}
                </span>
              </Nav.Link>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
      <Navbar.Toggle
        aria-controls="navbar-nav"
        className="ms-2 fs-6"
        onClick={handleNavClick}
      />
      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
        <Nav className="ms-auto">
          <Nav.Link
            as={Link}
            to="/config"
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="1"
          >
            <big>Build Your Own PC</big>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/prebuilts"
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="2"
          >
            <big>Pre-Builts</big>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/accessories"
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="3"
          >
            <big>Add-Ons</big>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={storedValue() ? "/tickets" : "/contact-us"}
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="4"
          >
            <big>Support</big>
          </Nav.Link>
          {storedValue() ? (
            <Nav.Link
              as={Link}
              to="/profile"
              onClick={handleNavClick}
              className="link-danger link-underline-opacity-0"
              eventKey="5"
              aria-label="My Profile"
            >
              <big>{user?.userInfo[0]?.firstname}</big>
            </Nav.Link>
          ) : (
            <Nav.Link
              as={Link}
              to="/login"
              onClick={handleNavClick}
              className="icon-small"
              eventKey="6"
              aria-label="My Profile"
            >
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                className="text-light"
                aria-label="My Profile"
              />
            </Nav.Link>
          )}
          <Nav.Link
            as={Link}
            to="/cart"
            onClick={handleNavClick}
            className="icon-small"
            eventKey="7"
            aria-label="My Cart"
          >
            <FontAwesomeIcon
              icon={faShoppingCart}
              size="lg"
              className={storedValue() ? "text-danger" : "text-light"}
              aria-label="My Cart"
            />
            {
              <span className={storedValue() ? "text-danger" : "text-light"}>
                {user?.cartInfo?.length > 0
                  ? "(" +
                    user?.cartInfo?.reduce(
                      (total, item) => total + item.qty,
                      0
                    ) +
                    ")"
                  : ""}
              </span>
            }
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
