import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { IoLogoFacebook, IoLogoYoutube, IoIosCall } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { useAuth } from "../Contexts/MyContext";

const Footer = () => {
  const { authContext } = useAuth();
  return (
    <div>
      <Container fluid className="border-top py-3 mt-5 bg-black">
        <Row className="justify-content-center mt-2 text-light">
          {/* Logo Section */}
          <Col lg={3} xs={12} className="mt-3">
            <Link to="/" className="p-3">
              <img
                src="/FG_Logo.webp"
                alt="Fusion Gaming Logo"
                width={80}
                height={80}
              />
            </Link>
            <Col className="mt-3">
              <span className="text-light" style={{ fontSize: "1.1rem" }}>
                THE PC FACTORY
              </span>
              <p>
                <span className="text-secondary">
                  Fusion Gaming excels in custom-built PCs <br></br>and boasts
                  over 1500+ loyal customers <br></br>all across India.
                </span>
              </p>
            </Col>
            <Col>
              <Link
                className="px-2 h2"
                to="https://www.instagram.com/fu5iong4ming/#"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Link to the official Fusion Gaming Instagram Page"
              >
                <FaInstagram style={{ fontSize: "1.3rem" }} />
              </Link>
              <Link
                className="px-2 h2"
                to="https://www.facebook.com/fu5ion.g4ming"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Link to the official Fusion Gaming Facebook Page"
              >
                <IoLogoFacebook style={{ fontSize: "1.3rem" }} />
              </Link>
              <Link
                className="px-2 h2"
                to="https://www.youtube.com/@fusion_gamingyt"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Link to the official Fusion Gaming YouTube Channel"
              >
                <IoLogoYoutube style={{ fontSize: "1.3rem" }} />
              </Link>
              <Link
                className="px-2 h2"
                to="tel:+91-6369933507"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Contact Fusion Gaming"
              >
                <IoIosCall style={{ fontSize: "1.3rem" }} />
              </Link>
              <Link
                className="px-2 h2"
                to="https://maps.app.goo.gl/KZWBYTEJvCdPxTou7"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Get directions to the Fusion Gaming Marina Mall Outlet"
              >
                <IoLocationSharp style={{ fontSize: "1.3rem" }} />
              </Link>
              <Link
                className="px-2 h2"
                to="https://www.linkedin.com/in/fusion-gaming-991a04222/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Link to the official Fusion Gaming LinkedIn Page"
              >
                <FaLinkedinIn style={{ fontSize: "1.3rem" }} />
              </Link>
            </Col>
          </Col>

          {/* About Section */}
          <Col lg={2} xs={6} className="mt-3" style={{ fontSize: "0.9rem" }}>
            <p className="text-uppercase" style={{ fontSize: "1.1rem" }}>
              About
            </p>
            <Container className="mt-3">
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/careers"
              >
                Careers
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/contact-us"
              >
                Contact us
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/video-consultation"
              >
                Video Consultation
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to={authContext ? "/tickets" : "/contact-us"}
              >
                Support
              </Link>
            </Container>
          </Col>
          {/* Quick Links Section */}
          <Col lg={2} xs={6} className="mt-3" style={{ fontSize: "0.9rem" }}>
            <p className="text-uppercase" style={{ fontSize: "1.1rem" }}>
              Quick Links
            </p>
            <Container className="mt-3">
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/config"
              >
                Build Your Own PC
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/prebuilts"
              >
                Pre-Builts
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/accessories"
              >
                Add-Ons
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to={authContext ? "/profile" : "/login"}
              >
                Profile
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to={authContext ? "/orders" : "/login"}
              >
                Orders
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to={authContext ? "/coupons" : "/login"}
              >
                Coupons
              </Link>
            </Container>
          </Col>

          {/* Useful Links Section */}
          <Col lg={2} xs={6} className="mt-3" style={{ fontSize: "0.9rem" }}>
            <p className="text-uppercase" style={{ fontSize: "1.1rem" }}>
              Useful Links
            </p>
            <Container className="mt-3">
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/cancellation-and-refund-policy"
              >
                Cancellation & Refund
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/terms-and-conditions"
              >
                Terms & Conditions
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/returns-policy"
              >
                Returns
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/shipping-policy"
              >
                Shipping
              </Link>
              <Link
                className="link-light link-underline-opacity-0 d-block mb-2"
                to="/privacy-policy"
              >
                Privacy
              </Link>
            </Container>
          </Col>

          {/* Location & Contact Section */}
          <Col lg={3} xs={6} className="mt-3" style={{ fontSize: "0.9rem" }}>
            <p className="text-uppercase" style={{ fontSize: "1.1rem" }}>
              CONTACT INFO
            </p>
            <Container className="mt-3 text-secondary">
              <a
                className="link-danger link-underline-opacity-0 d-block"
                href="https://maps.app.goo.gl/KZWBYTEJvCdPxTou7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLocationSharp />
                The Marina Mall, <br />
                2nd Floor, Below INOX, Egattur, Chennai, <br />
                Tamil Nadu - 603103.
              </a>
              <a
                className="link-danger link-underline-opacity-0 mt-2 d-block"
                href="mailto:future.retail20@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {window.innerWidth > 440 ? <CiMail /> : ""}{" "}
                future.retail20@gmail.com
              </a>
              <a
                className="link-danger link-underline-opacity-0 mt-2 d-block"
                href="tel:+91-6369933507"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoIosCall /> +91-63699 33507
              </a>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
