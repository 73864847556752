import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaTag, FaTools, FaAward, FaAnchor } from "react-icons/fa"; // Icon imports

const Row4 = () => {
  const usps = [
    {
      title: " Competitve Pricing ",
      description:
        "Experience the best competitive pricing in the industry, providing exceptional value for your investment.",
      icon: <FaTag size={40} />,
    },
    {
      title: "High Quality",
      description:
        "Access the latest, most reliable components, expertly chosen for top performance and durability in every build.",
      icon: <FaAnchor size={40} />,
    },
    {
      title: "Lifetime Support",
      description:
        "Enjoy free lifetime service support with every purchase for lasting performance and peace of mind.",
      icon: <FaTools size={40} />,
    },
    {
      title: "Authorised Integrators",
      description:
        "We are authorized system Integrators certified by NVIDIA, AMD, and Intel.",
      icon: <FaAward size={40} />,
    },
  ];

  return (
    <Container className="mt-5">
      <h2 className="text-center text-danger mb-5">
        The Fusion Gaming Difference
      </h2>
      <Row>
        {usps.map((usp, index) => (
          <Col key={index} md={3} xs={6} className="mb-4">
            <Card className="usp-card" style={{ height: "100%" }}>
              <Card.Body className="text-center">
                <div className="usp-icon mb-3">{usp.icon}</div>
                <Card.Title className="text-danger">{usp.title}</Card.Title>
                <Card.Text>{usp.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Row4;
