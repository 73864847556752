import React, { useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  Accordion,
  ListGroupItem,
  CardBody,
  ListGroup,
} from "react-bootstrap";

const FaqSection = () => {
  // Data for FAQ topics and Q&A
  const faqData = [
    {
      topic: "General",
      questions: [
        {
          question: "Does Fusion Gaming build only Gaming PCs?",
          answer:
            "At Fusion Gaming, we build custom PCs for everyone—from simple home setups to powerful machines for AI, gaming, and creative work. Whether you are a gamer, a creator, or need a PC for tough tasks, our team knows the latest games and professional tools. We'll design the perfect build just for you and your budget!",
        },
        {
          question: "What are the components used in the build?",
          answer:
            "We use only top-quality, trusted components with warranties that last from three years to a lifetime. Every component is carefully selected to build a reliable system you can count on, giving you peace of mind.",
        },
        {
          question: "How much should one pay for the assembly?",
          answer:
            "At Fusion Gaming, we assemble your PC for free—you only pay for the parts. We also offer some of the best prices for PC builds across India, giving you great value without sacrificing performance.",
        },
        {
          question: "Does Fusion Gaming accept bulk or corporate orders?",
          answer:
            "Yes, we accept bulk and corporate orders. Please feel free to contact us at +91 63699 33507 or future.retail20@gmail.com for any inquiries regarding bulk purchases.",
        },
      ],
    },
    {
      topic: "Orders",
      questions: [
        {
          question:
            "What is the warranty period for the PCs ordered from Fusion Gaming?",
          answer:
            "We offer warranties from three years to a lifetime on selected parts, based on the manufacturer's coverage, plus a 7-day dead-on-arrival policy. You can submit warranty claims either directly through us or the manufacturer. Our parts come from top brands with service centres in major cities, so you can easily access support when needed.",
        },
        {
          question: "How long does it take to dispatch the order?",
          answer:
            "After you place your order on our website, it usually takes 24 to 72 working hours to ship, depending on component availability. If any parts are out of stock or need changes, one of our technicians will contact you for confirmation.",
        },
        {
          question: "When will i receive my order from the date of purchase?",
          answer:
            "Once your order is dispatched, delivery usually takes 48 to 72 hours, depending on your location. A tracking ID will be provided, so you can track your order easily.",
        },
        {
          question: "How do I modify/cancel an order? ",
          answer:
            "After you place an order on our website, a team member will contact you to confirm the details. During this call, you can request changes, cancellations, or ask any questions. Once your order is confirmed, cancellations or refunds may not be possible.",
        },
        {
          question:
            "What are the payment options available with Fusion Gaming?",
          answer:
            "We accept various payment methods, including NEFT, RTGS, UPI, all major credit and debit cards, and Bajaj Finance EMI Card(Offline). Our payment gateway is secured by PhonePe, ensuring your payment is fully safe.",
        },
      ],
    },
    {
      topic: "Support",
      questions: [
        {
          question: "How do I check my order update?",
          answer:
            "You can check your order status by navigating to User Dashboard -> Orders. Once your order has been dispatched, the tracking ID will be updated here for your convenience, allowing you to easily monitor the transit status.",
        },
        {
          question: "How to raise a support request?",
          answer:
            "For Order related support go to My Orders section in your dashboard. Click 'Help & Support' on the specific order and fill in the required details. You will receive a call within 24 to 48 hours. Alternatively, you can call us at +91 63819 44754 or email support@fusiongaming.in. You can also request for a call back if you have any purchase related queries.",
        },
        {
          question: "What if my order arrives damaged?",
          answer:
            "Our dedicated team securely packs your PC to ensure all components are safe. We recommend recording a video during unboxing, any damage shown in the video can be replaced if you raise a support ticket. At Fusion Gaming, we run thorough tests on each PC to ensure all components are stable and reliable, reducing the risk of dead-on-arrival issues. If any issues do occur, we can replace the affected part if you submit a support ticket within seven working days.",
        },
        {
          question: "How to remove the packaging and assemble the PC?",
          answer: (
            <CardBody>
              <p>
                {" "}
                We recommend recording a video during unpacking to document any
                damage from transit. After removing the bubble wrap, you'll find
                the PC securely packed in the cabinet box. To unpack, open the
                top of the carton, place it upside down on a flat surface, and
                lift the box off, leaving the PC on the surface.
              </p>
              <ListGroup variant="flush">
                {" "}
                <p> Before using the machine, please ensure the following: </p>
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • All plastic protective stickers are removed from the glass
                side.{" "}
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • The PSU switch is turned on.
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • USB cables for accessories are connected to the rear ports of
                the CPU.
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • The HDMI or DisplayPort cable from your monitor is connected
                to the output ports on the GPU, located at the bottom of the IO
                ports behind the CPU. If you have purchased a system with an
                integrated GPU processor, you may use the display output ports
                on the motherboard instead.{" "}
              </ListGroup>
            </CardBody>
          ),
        },
      ],
    },
  ];

  const [key, setKey] = useState("0"); // State to manage active tab key

  return (
    <div style={{ backgroundColor: "rgb(15, 15, 15)", height: "100%" }}>
      <Container className="mt-5 py-5">
        <h1 className="text-center text-danger mb-5">Get To Know Us Better</h1>

        <Tabs
          id="faq-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)} // Set active tab
          className="mb-4"
          variant="pills"
          justify
          fill
        >
          {faqData.map((topic, index) => (
            <Tab eventKey={String(index)} title={topic.topic} key={index}>
              <Accordion defaultActiveKey="0">
                {topic.questions.map((qa, qaIndex) => (
                  <Accordion.Item eventKey={String(qaIndex)} key={qaIndex}>
                    <Accordion.Header>{qa.question}</Accordion.Header>
                    <Accordion.Body>
                      {qa.answer}
                      <ListGroupItem></ListGroupItem>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </div>
  );
};

export default FaqSection;
