import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { useAuth } from "../Contexts/MyContext";

import { auth } from "../Firebase/firebase";
import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { url } from "../../config";
import {
  validEmail,
  validName,
  validPhone,
  validPassword,
} from "../Regex/Regex";

const Register = () => {
  const navigate = useNavigate();
  const { authContext, setEmail, setPassword } = useAuth();
  const [subLoading, setSubLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    retypePassword: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (authContext) {
      navigate("/profile");
    }
  }, [authContext]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e) => {
    setSubLoading(true);
    e.preventDefault();
    setError("");
    if (!formData.firstName || !validName.test(formData.firstName)) {
      setError("Please enter a valid first name!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.lastName || !validName.test(formData.lastName)) {
      setError("Please enter a valid last name!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.phone || !validPhone.test(formData.phone)) {
      setError("Please enter a valid 10 digit phone number!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.password || !validPassword.test(formData.password)) {
      setError("Please enter a valid password!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (formData.password !== formData.retypePassword) {
      setError("Passwords do not match! Try again!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    registerUser(formData);
  };

  async function registerUser(data) {
    try {
      // Step 1: Create user and send email verification
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      const user = userCredential.user;

      if (user) {
        // Step 2: Register user in your backend
        const userData = {
          id: user.uid,
          firstName: data.firstName,
          lastName: data.lastName,
          number: data.phone,
          email: data.email,
        };
        await registerUserInBackend(userData);
        setEmail(data.email);
        setPassword(data.password);
        notifyUser("Kindly verify Email...", "/unauthorized", 2000);
      }
    } catch (error) {
      // Step 5: Handle errors and cleanup
      await handleRegisterError(error);
    }
  }

  // Helper function to register user in the backend
  async function registerUserInBackend(userData) {
    try {
      await axios.post(`${url}/register`, userData, { withCredentials: true });
    } catch (err) {
      console.error("Error registering user in backend:", err);
      // Attempt to delete user if one exists
      if (auth.currentUser) {
        try {
          await deleteUser(auth.currentUser);
        } catch (deleteError) {
          console.error("Failed to delete user:", deleteError);
        }
      }
      throw new Error("Backend registration failed");
    }
  }

  // Helper function to handle registration errors
  async function handleRegisterError(error) {
    console.error("Registration error:", error);

    // Provide specific feedback to the user
    if (error.code === "auth/email-already-in-use") {
      notifyUser("Email already in use. Try Sign In!", "/login", 3000);
    } else {
      notifyUser("An error occurred during Sign Up! Try Again!", null, 0);
    }
  }

  // Helper function to notify the user and navigate
  function notifyUser(message, redirectPath, timeout = 0) {
    setError(message);
    setShowAlert(true);
    if (timeout > 0) {
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
        if (redirectPath) navigate(redirectPath);
      }, timeout);
    } else {
      setSubLoading(false);
    }
  }

  return (
    <div className="bg-black text-white py-5 mt-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              SIGN UP
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={5}>
            <h5
              style={{
                fontSize: "1.1rem",
                letterSpacing: "1px",
              }}
              className="text-center text-light mb-4"
            >
              Welcome to Fusion Gaming!!
            </h5>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formFirstName" className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  className="bg-light"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formLastName" className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  className="bg-light"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  maxLength={10}
                  name="phone"
                  className="bg-light"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  className="bg-light"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formRetypePassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Retype password"
                  name="retypePassword"
                  value={formData.retypePassword}
                  className="bg-light"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Row className="justify-content-center">
                <Col className="h5 mt-1 text-light col-12">
                  <Container className="text-center text-secondary">
                    <p
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      Your password must be minimum eight characters with at
                      least one uppercase letter, one lowercase letter, one
                      number and one special character.
                    </p>
                  </Container>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-1 text-light col-12">
                  <Container className="text-center text-secondary">
                    <p
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      By clicking submit, you agree to our{" "}
                      <Link className="link-danger" to="/privacy-policy">
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link className="link-danger" to="/terms-and-conditions">
                        Terms & Conditions
                      </Link>
                      .
                    </p>
                  </Container>
                </Col>
              </Row>
              {showAlert && (
                <Row>
                  <Col className="h5 text-center mt-3 text-danger">{error}</Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button
                    variant="danger"
                    size="md"
                    onClick={handleSubmit}
                    disabled={subLoading}
                  >
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
                <Col xxl={12} className="text-center text-secondary mt-3">
                  <small>
                    Please call us at{" "}
                    <Link className="link-danger" to="tel:+91-6369933507">
                      +91-6369933507
                    </Link>{" "}
                    if you face any issues while signing up.
                  </small>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
