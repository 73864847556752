import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { url } from "../../config";
import { validEmail } from "../Regex/Regex";

import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
} from "../Firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../Contexts/MyContext";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const { user, authContext, setUser, setAuthContext, setEmail, setPassword } =
    useAuth();
  const cart = user.cartInfo;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [googleError, setGoogleError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    setSubLoading(true);
    e.preventDefault();
    setError("");
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    loginUser(formData);
  };

  // Helper function to create refresh token
  const createRefreshToken = async (refreshToken, uid) => {
    try {
      await axios.post(
        `${url}/createCookie`,
        { refreshToken, uid },
        { withCredentials: true }
      );
    } catch (err) {
      console.error("Failed to create refresh token:", err);
    }
  };

  // Helper function to fetch user data
  const fetchUserData = async (uid) => {
    try {
      const res = await axios.post(
        `${url}/fetchAllUserDetails`,
        { id: uid },
        { withCredentials: true }
      );
      const fetchedCart = res.data.cartInfo;
      const updatedCart = cart.filter(
        (item) =>
          !fetchedCart.some((existingItem) => existingItem.item === item.item)
      );
      let offlineCartItems = null;
      if (updatedCart.length) {
        offlineCartItems = await addToCart(
          res.data.userInfo[0].id,
          updatedCart
        );
      }
      const resolvedCart = await offlineCartItems;
      if (offlineCartItems !== null) {
        setUser({
          ...res.data,
          cartInfo: resolvedCart,
        });
      } else {
        setUser(res.data);
      }
      return res.data;
    } catch (err) {
      console.error("Failed to fetch user data:", err);
      return null;
    }
  };

  // Helper function to add offline items to cart
  const addToCart = async (id, items) => {
    if (!items.length) return null;
    try {
      const promises = items.map((item) =>
        axios.post(
          `${url}/addToCart`,
          {
            userid: id,
            item: item.item,
            itemType: item.itemType,
            itemShort: item.itemShort,
            qty: item.qty || 1,
            stock: "Y",
          },
          { withCredentials: true }
        )
      );
      const results = await Promise.all(promises);
      return results[0].data;
    } catch (err) {
      setLoading(false);
      console.error("Error adding items to cart:", err);
    }
  };

  // Function to handle login with email/password
  const loginUser = async (data) => {
    try {
      setSubLoading(true);
      const { email, password } = data;

      // Sign in with email and password
      await signInWithEmailAndPassword(auth, email, password);

      const currentUser = auth.currentUser;
      if (currentUser.emailVerified) {
        const userData = await fetchUserData(currentUser.uid);
        if (userData) {
          await createRefreshToken(currentUser.refreshToken, currentUser.uid);
          setAuthContext(currentUser.accessToken);
          setError("Logged In Successfully!");
          navigate(-1);
        }
      } else {
        setError("Email verification pending. Please check your inbox.");
        setEmail(formData.email);
        setPassword(formData.password);
        navigate("/unauthorized");
      }
    } catch (err) {
      handleLoginError(err, "email/password");
    }
  };

  // Function to handle Google login
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);

      const userGoogle = result.user;
      const {
        displayName = "",
        email = "",
        phoneNumber = "",
        uid,
      } = userGoogle;
      const accessToken = userGoogle.accessToken;
      const refreshToken = userGoogle.refreshToken;

      // Split display name into first and last names
      const [firstName = "", ...lastNameParts] = displayName.split(" ");
      const lastName = lastNameParts.join(" ");

      // Fetch or register user
      const userData = await fetchUserData(uid);

      if (!userData?.userInfo?.length) {
        await axios.post(
          `${url}/register`,
          {
            id: uid,
            firstName,
            lastName,
            number: phoneNumber || null,
            email,
          },
          { withCredentials: true }
        );
        await fetchUserData(uid); // Refresh user data
      }

      // Create refresh token and set authentication context
      await createRefreshToken(refreshToken, uid);
      setAuthContext(accessToken);
      navigate(-1);
    } catch (err) {
      handleLoginError(err, "Google");
    } finally {
      setLoading(false);
    }
  };

  // Error handling function
  const handleLoginError = (err, method) => {
    console.error(`Error during ${method} login:`, err);

    if (method === "email/password") {
      if (err.code === "auth/invalid-credential") {
        setError("Incorrect email or password. Please try again.");
      } else if (err.code === "auth/too-many-requests") {
        setError("Email verification pending. Kindly verify and login.");
        navigate("/unauthorized");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } else if (method === "Google") {
      if (err.code === "auth/popup-closed-by-user") {
        setGoogleError("Google login failed. Please try again.");
      }
      setGoogleError("Google login failed. Please try again.");
    }

    setShowAlert(true);
    setTimeout(() => {
      if (error === "mail verification pending. Kindly verify and login.") {
        navigate("/unauthorized");
      }
      setError("");
      setGoogleError("");
      setShowAlert(false);
      setSubLoading(false);
    }, 3000);
  };

  useEffect(() => {
    if (authContext) {
      navigate("/profile");
    }
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="bg-black text-white py-5 mt-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              LOGIN
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <h5
              style={{
                fontSize: "1.1rem",
                letterSpacing: "1px",
              }}
              className="text-center text-light mb-4"
            >
              NEW TO FUSION GAMING?{" "}
              <Link
                to="/register"
                className="link-danger link-underline-opacity-0"
              >
                SIGN UP
              </Link>
            </h5>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={formData.password}
                  className="bg-light"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Row className="justify-content-center">
                <Col className="h5 mt-1 text-light col-12">
                  <Container className="text-center text-secondary">
                    <p
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      By clicking submit, you agree to our{" "}
                      <Link className="link-danger" to="/privacy-policy">
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link className="link-danger" to="/terms-and-conditions">
                        Terms & Conditions
                      </Link>
                      .
                    </p>
                  </Container>
                </Col>
              </Row>
              {showAlert &&
                (error !== "" ? (
                  <Row>
                    <Col className="h5 text-center mt-3 text-danger">
                      {error}
                    </Col>
                  </Row>
                ) : (
                  ""
                ))}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button
                    variant="danger"
                    size="md"
                    onClick={handleSubmit}
                    disabled={subLoading}
                  >
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col
                  className="h5 mt-1 text-center text-light col-12"
                  style={{ fontSize: "1rem" }}
                >
                  <span>Forgot Password? </span>
                  <Link
                    className="link-danger link-underline-opacity-0"
                    to="/forgotpassword"
                  >
                    Click Here
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  Or
                </Col>
              </Row>
              {showAlert &&
                (googleError !== "" ? (
                  <Row>
                    <Col className="h5 text-center mt-3 text-danger">
                      {googleError}
                    </Col>
                  </Row>
                ) : (
                  ""
                ))}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-6">
                  <Button variant="outline-danger" onClick={handleGoogleLogin}>
                    <FcGoogle /> Sign in with Google
                  </Button>
                </Col>
                <Col xxl={12} className="text-center text-secondary mt-3">
                  <small>
                    Please call us at{" "}
                    <Link className="link-danger" to="tel:+91-6369933507">
                      +91-6369933507
                    </Link>{" "}
                    if you face any issues while logging in.
                  </small>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
