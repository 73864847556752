import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useEffect } from "react";
import { auth } from "../Firebase/firebase";
import { useAuth } from "../Contexts/MyContext";
import { url } from "../../config";
import axios from "axios";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

const Unauthorized = () => {
  const navigate = useNavigate();
  const {
    authContext,
    setUser,
    setAuthContext,
    user,
    email,
    password,
    setEmail,
    setPassword,
  } = useAuth();
  const cart = user.cartInfo;
  let added = "N";

  useEffect(() => {
    const reload = async () => {
      const currentUser = auth.currentUser;
      await currentUser.reload();
      if (auth.currentUser && auth.currentUser.emailVerified) {
        const userData = await fetchUserData(currentUser.uid);
        if (userData) {
          await createRefreshToken(currentUser.refreshToken, currentUser.uid);
          setAuthContext(currentUser.accessToken);
          setEmail(null);
          setPassword(null);
          navigate(-3);
        }
      }
    };
    const checkUserState = setInterval(() => {
      reload();
    }, 5000); // Check every 5 seconds

    return () => clearInterval(checkUserState); // Cleanup on unmount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authContext) {
      navigate(-3);
    } else if (!email) {
      navigate("/login");
    } else {
      loginAndVerify();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loginAndVerify = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const currentUser = auth.currentUser;
      await sendEmailVerification(currentUser);

      if (currentUser) {
        const pollEmailVerification = setInterval(async () => {
          await currentUser.reload(); // Refresh the user's state

          if (currentUser.emailVerified) {
            clearInterval(pollEmailVerification); // Stop polling

            // Fetch user data and update context
            const userData = await fetchUserData(currentUser.uid);
            if (userData) {
              await createRefreshToken(
                currentUser.refreshToken,
                currentUser.uid
              );
              setAuthContext(currentUser.accessToken);
              setEmail(null);
              setPassword(null);
              navigate(-3); // Navigate back after successful verification
            }
          } else {
            console.log("Email not verified yet. Retrying...");
          }
        }, 7000); // Poll every 7 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(pollEmailVerification);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  // Fetch user data from the backend
  const fetchUserData = async (uid) => {
    try {
      const response = await axios.post(
        `${url}/fetchAllUserDetails`,
        { id: uid },
        { withCredentials: true }
      );
      if (added === "N") {
        let offlineCartItems = null;
        if (cart.length) {
          offlineCartItems = await addToCart(
            response.data.userInfo[0].id,
            cart
          );
        }
        const resolvedCart = await offlineCartItems;
        if (offlineCartItems !== null) {
          setUser({
            ...response.data,
            cartInfo: resolvedCart,
          });
        } else {
          setUser(response.data);
        }
        added = "Y";
      }
      return response.data;
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  // Create a refresh token for the user
  const createRefreshToken = async (refreshToken, uid) => {
    try {
      await axios.post(
        `${url}/createCookie`,
        { refreshToken, uid },
        { withCredentials: true }
      );
    } catch (err) {
      console.error("Error creating refresh token:", err);
    }
  };

  // Helper function to add offline items to cart
  const addToCart = async (id, items) => {
    if (!items.length) return null;
    try {
      const promises = items.map((item) =>
        axios.post(
          `${url}/addToCart`,
          {
            userid: id,
            item: item.item,
            itemType: item.itemType,
            itemShort: item.itemShort,
            qty: item.qty || 1,
            stock: "Y",
          },
          { withCredentials: true }
        )
      );
      const results = await Promise.all(promises);
      return results[0].data;
    } catch (err) {
      console.error("Error adding items to cart:", err);
    }
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col
          className="col-12 h1 mt-5 text-danger mb-5 text-center"
          data-bs-theme="dark"
        >
          Pending Email Verification
        </Col>
        <Col
          className="col-12 h5 mt-5 text-light mb-5 text-center"
          data-bs-theme="dark"
        >
          Your Email needs to be verified!
        </Col>
        <Col
          className="col-12 h5 mt-5 mb-5 text-center text-secondary"
          data-bs-theme="dark"
        >
          Check your inbox. Once verified, this page will redirect
          automatically!
        </Col>
      </Row>
    </Container>
  );
};

export default Unauthorized;
