import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { url, discount } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import {
  Tabs,
  Tab,
  Card,
  Container,
  Col,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import ShareButton from "../Share/ShareButton";
import uniqid from "uniqid";

const PreBuiltViewer = () => {
  const { authContext, user, setUser } = useAuth();
  const { useCase, id } = useParams();
  const [pc, setPc] = useState();
  const [updatedPrice, setUpdatedPrice] = useState();
  const [pcShort, setPcShort] = useState();
  const navigate = useNavigate();
  const [key, setKey] = useState("0");
  const [msg, setMsg] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const getPc = async (useCase, id) => {
      try {
        const res = await axios.post(
          url + "/getPc",
          { useCase, id },
          { withCredentials: true }
        );
        setPc(JSON.parse(res.data[0].pc));
        setUpdatedPrice(res.data[0].updatedPrice);
        setPcShort(res.data[0].pcShort);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        navigate("/prebuilts");
      }
    };
    getPc(useCase, id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendToCart = (pc) => {
    setSubLoading(true);

    if (
      user.cartInfo.filter((i) => {
        return i.itemShort === pcShort;
      }).length === 0
    ) {
      if (authContext !== null) {
        addToCart(pcShort, pc);
      } else {
        setUser((prevUser) => {
          return {
            ...prevUser,
            cartInfo: [
              ...prevUser.cartInfo, // Spread existing items in cartInfo
              {
                itemid: uniqid(),
                userid: "temp", // Temporary user or update as necessary
                itemType: "PB",
                itemShort: pcShort,
                item: JSON.stringify(pc),
                qty: 1,
                stock: "Y",
                created: Date.now(),
                checkoutInfo: null,
                checkouttime: null,
                updatedPrice: null,
              },
            ],
          };
        });
        setMsg("Item added to cart!");
        setTimeout(() => {
          setMsg("");
          setSubLoading(false);
        }, 2000);
      }
    } else {
      setMsg("Item already in cart!");
      setTimeout(() => {
        setMsg("");
        setSubLoading(false);
      }, 2000);
    }
  };
  const addToCart = async (itemShort, item) => {
    try {
      const res = await axios.post(
        url + "/addToCart",
        {
          userid: user.userInfo[0].id,
          item: JSON.stringify(item),
          itemType: "PB",
          itemShort,
          qty: 1,
          stock: "Y",
        },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setMsg("Item added to cart!");
      setTimeout(() => {
        setMsg("");
        setSubLoading(false);
      }, 2000);
    } catch (err) {
      setSubLoading(false);
      console.log(err);
    }
  };

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return pc ? (
    <Container className="mt-5" style={{ backgroundColor: "#151515" }}>
      <Col
        xxl={12}
        className="h4 pt-5 text-center text-danger"
        style={{ backgroundColor: "#151515" }}
      >
        {title(updatedPrice)}
      </Col>
      <Col
        xxl={12}
        className="h4 mt-1 mb-5 text-center text-danger"
        style={{ backgroundColor: "#151515" }}
      >
        {capitalizeFirstLetter(useCase)} Pre-Built
      </Col>
      {width < 992 ? (
        <>
          <Col className="text-end p-2">
            <ShareButton
              url={`https://www.fusiongaming.in${location.pathname}${location.search}`}
            />
          </Col>
          <Row>
            <Card.Img
              src={
                "/cabinet/" +
                pc.cabinetBrand +
                "/" +
                pc.cabinetBrand +
                " " +
                pc.cabinetModel +
                ".webp"
              }
              alt="PC"
              className="img-fluid"
            />
          </Row>
          <Col
            xxl={12}
            className="text-secondary text-center mb-3"
            style={{ fontSize: "0.7rem" }}
          >
            *Image For Reference Only*
          </Col>
          <Col xxl={12} className="text-secondary text-center mb-5">
            <Link to="tel:+91-6369933507" className="link-danger  d-block">
              For More Customizations: <br /> +91-6369933507
            </Link>
          </Col>
        </>
      ) : (
        <></>
      )}
      <Tabs
        id="pb-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-4"
        variant="pills"
        justify
        fill
      >
        <Tab eventKey={"0"} title="Overview" key={"0"}>
          <Card
            className="shadow-sm text-light "
            style={{ backgroundColor: "#151515" }}
          >
            <Row>
              <Col lg={6} md={12}>
                {width > 992 ? (
                  <>
                    <Col className="text-end p-2">
                      <ShareButton
                        url={`https://www.fusiongaming.in${location.pathname}${location.search}`}
                      />
                    </Col>
                    <Row>
                      <Card.Img
                        src={
                          "/cabinet/" +
                          pc.cabinetBrand +
                          "/" +
                          pc.cabinetBrand +
                          " " +
                          pc.cabinetModel +
                          ".webp"
                        }
                        alt="PC"
                        className="img-fluid mt-4"
                      />
                    </Row>
                    <Col
                      className="text-secondary text-center mb-3"
                      style={{ fontSize: "0.7rem" }}
                    >
                      *Image For Reference Only*
                    </Col>
                    <Col className="text-secondary text-center mb-3">
                      <Link
                        to="tel:+91-6369933507"
                        className="link-danger  d-block"
                      >
                        For More Customizations: <br /> +91-6369933507
                      </Link>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col lg={6} md={12}>
                <Card.Body>
                  <h5 className="text-danger">Specifications</h5>
                  <ul>
                    <li className="mt-4">
                      Processor: {pc.processor} <br />
                      <span className="text-secondary">
                        ({pc.warranty?.processor}-year warranty)
                      </span>
                    </li>
                    <li className="mt-2">
                      Motherboard: {pc.chipset}
                      <br />
                      <span className="text-secondary">
                        ({pc.warranty?.chipset}-year warranty)
                      </span>
                    </li>
                    <li className="mt-2">
                      GPU:{" "}
                      {pc.gpuBrand !== "In-Built"
                        ? pc.gpuBrand + " " + pc.gpuModel
                        : pc.igpu}
                      <br />
                      <span className="text-secondary">
                        ({pc.warranty?.gpu}-year warranty)
                      </span>
                    </li>
                    <li className="mt-2">
                      RAM: {pc.ramSize} {pc.ramType}{" "}
                      {pc.ramRGB === "Y" ? "RGB" : ""}
                      <br />
                      <span className="text-secondary">
                        (
                        {pc.warranty?.ram === 99
                          ? "Limited Lifetime Warranty"
                          : pc.warranty?.ram + "-year warranty"}
                        )
                      </span>
                    </li>
                    <li className="mt-2">
                      Cooler: {pc.cooler}
                      <br />
                      <span className="text-secondary">
                        ({pc.warranty?.cooler}-year warranty)
                      </span>
                    </li>
                    <li className="mt-2">
                      PSU: {pc.psu}
                      <br />
                      <span className="text-secondary">
                        ({pc.warranty?.psu}-year warranty)
                      </span>
                    </li>
                    <li className="mt-2">
                      Primary Storage: {pc.primaryStorage}
                      <br />
                      <span className="text-secondary">
                        ({pc.warranty?.primaryStorage}-year warranty)
                      </span>
                    </li>
                    {pc.secondaryStorage !== "Not Available" ? (
                      <li>
                        Secondary Storage: {pc.secondaryStorage}
                        <br />
                        <span className="text-secondary">
                          ({pc.warranty?.secondaryStorage}-year warranty)
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="mt-2">
                      Cabinet: {pc.cabinetBrand} {pc.cabinetModel}
                      <br />
                      <span className="text-secondary">
                        ({pc.warranty?.cabinet}-year warranty)
                      </span>
                    </li>
                    <li className="mt-2">
                      Max Power Consumption:{" "}
                      {((pc.tdp.processorTdp + pc.tdp.gpuTdp) * 1.25).toFixed(
                        0
                      )}
                      W
                    </li>
                  </ul>
                  <h5 className="text-danger">Connectivity</h5>
                  <ul>
                    <li className="mt-4">
                      USB ports:{" "}
                      {pc.connectivity.chipsetConnectivity.usb30 +
                        pc.connectivity.chipsetConnectivity.usb20 +
                        pc.connectivity.chipsetConnectivity.usb32a +
                        pc.connectivity.chipsetConnectivity.usb32c +
                        pc.connectivity.cabinetConnectivity.usb30 +
                        pc.connectivity.cabinetConnectivity.usb20 +
                        pc.connectivity.cabinetConnectivity.usb32a +
                        (pc.connectivity.chipsetConnectivity.tch > 0
                          ? pc.connectivity.cabinetConnectivity.usb32c
                          : 0)}{" "}
                      USB Ports
                      {pc.connectivity.chipsetConnectivity.usb32a +
                        pc.connectivity.cabinetConnectivity.usb32a >
                      0
                        ? " (" +
                          (pc.connectivity.chipsetConnectivity.usb32a +
                            pc.connectivity.cabinetConnectivity.usb32a) +
                          "x USB 3.2 Type-A)"
                        : ""}{" "}
                      {pc.connectivity.cabinetConnectivity.usb30 +
                        pc.connectivity.chipsetConnectivity.usb30 >
                      0
                        ? "(" +
                          (pc.connectivity.cabinetConnectivity.usb30 +
                            pc.connectivity.chipsetConnectivity.usb30) +
                          "x USB 3.0 Type-A)"
                        : ""}{" "}
                      {pc.connectivity.chipsetConnectivity.usb20 +
                        pc.connectivity.cabinetConnectivity.usb20 >
                      0
                        ? "(" +
                          (pc.connectivity.chipsetConnectivity.usb20 +
                            pc.connectivity.cabinetConnectivity.usb20) +
                          "x USB 2.0 Type-A)"
                        : ""}{" "}
                      {pc.connectivity.chipsetConnectivity.usb32c +
                        (pc.connectivity.chipsetConnectivity.tch > 0
                          ? pc.connectivity.cabinetConnectivity.usb32c
                          : 0) >
                      0
                        ? "(" +
                          (pc.connectivity.chipsetConnectivity.usb32c +
                            (pc.connectivity.chipsetConnectivity.tch > 0
                              ? pc.connectivity.cabinetConnectivity.usb32c
                              : 0)) +
                          "x USB 3.2 Type-C)"
                        : ""}
                      .
                    </li>
                    <li className="mt-2">
                      Display Support:{" "}
                      {pc.gpuBrand === "In-Built"
                        ? pc.connectivity.chipsetDisplay[0]
                        : pc.connectivity.gpuDisplay[0]}
                    </li>
                    <li className="mt-2">
                      WiFi:{" "}
                      {pc.connectivity.chipsetConnectivity.wifi
                        ? pc.connectivity.chipsetConnectivity.wifi
                        : "No WiFi Support."}
                    </li>
                    <li className="mt-2">
                      Bluetooth:{" "}
                      {pc.connectivity.chipsetConnectivity.bt
                        ? pc.connectivity.chipsetConnectivity.bt
                        : "No Bluetooth Support."}
                    </li>
                    <li className="mt-2">
                      LAN: {pc.connectivity.chipsetConnectivity.lan}
                    </li>
                    <li className="mt-2">
                      Audio: {pc.connectivity.chipsetConnectivity.audio}
                    </li>
                  </ul>
                  <h5 className="text-danger">Expansion availability</h5>
                  <ul>
                    {pc.expansion.chipsetM2Slots -
                      (pc.expansion.psPort === "m.2" ? 1 : 0) -
                      (pc.expansion.ssPort === "m.2" ? 1 : 0) >
                    0 ? (
                      <li className="mt-4">
                        m.2 devices:{" "}
                        {pc.expansion.chipsetM2Slots -
                          (pc.expansion.psPort === "m.2" ? 1 : 0) -
                          (pc.expansion.ssPort === "m.2" ? 1 : 0)}{" "}
                        slots available
                      </li>
                    ) : (
                      ""
                    )}
                    {(pc.expansion.chipsetSataPorts -
                      (pc.expansion.psPort === "sata" ? 1 : 0) -
                      (pc.expansion.ssPort === "sata" ? 1 : 0) >
                    pc.expansion.cabinetSata.sata25 +
                      pc.expansion.cabinetSata.sata35
                      ? pc.expansion.cabinetSata.sata25 +
                        pc.expansion.cabinetSata.sata35
                      : pc.expansion.chipsetSataPorts -
                        (pc.expansion.psPort === "sata" ? 1 : 0) -
                        (pc.expansion.ssPort === "sata" ? 1 : 0)) > 0 ? (
                      <li className="mt-2">
                        SATA devices: Upto{" "}
                        {pc.expansion.chipsetSataPorts -
                          (pc.expansion.psPort === "sata" ? 1 : 0) -
                          (pc.expansion.ssPort === "sata" ? 1 : 0) >
                        pc.expansion.cabinetSata.sata25 +
                          pc.expansion.cabinetSata.sata35
                          ? pc.expansion.cabinetSata.sata25 +
                            pc.expansion.cabinetSata.sata35
                          : pc.expansion.chipsetSataPorts -
                            (pc.expansion.psPort === "sata" ? 1 : 0) -
                            (pc.expansion.ssPort === "sata" ? 1 : 0)}{" "}
                        SATA devices
                      </li>
                    ) : (
                      ""
                    )}
                    {pc.expansion.chispetRamSlots - pc.expansion.ramCount >
                    0 ? (
                      <li className="mt-2">
                        RAM:{" "}
                        {pc.expansion.chispetRamSlots - pc.expansion.ramCount}{" "}
                        slots available
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="mt-2">
                      Power Headroom Available:{" "}
                      {pc.psu.match(/^(\d+)[W]/)[1] -
                        (pc.tdp.processorTdp + pc.tdp.gpuTdp * 1.25).toFixed(0)}
                      {"W"}
                    </li>
                  </ul>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Tab>
        <Tab eventKey={"1"} title="Benchmarks" key={"1"}>
          <Card
            className="shadow-sm text-light "
            style={{ backgroundColor: "#151515" }}
          >
            <Row>
              <Col lg={6} md={12}>
                {width > 992 ? (
                  <>
                    <Col className="text-end p-2">
                      <ShareButton
                        url={`https://www.fusiongaming.in${location.pathname}${location.search}`}
                      />
                    </Col>
                    <Row>
                      <Card.Img
                        src={
                          "/cabinet/" +
                          pc.cabinetBrand +
                          "/" +
                          pc.cabinetBrand +
                          " " +
                          pc.cabinetModel +
                          ".webp"
                        }
                        alt="PC"
                        className="img-fluid mt-4"
                      />
                    </Row>
                    <Col
                      className="text-secondary text-center mb-3"
                      style={{ fontSize: "0.7rem" }}
                    >
                      *Image For Reference Only*
                    </Col>
                    <Col className="text-secondary text-center mb-3">
                      <Link
                        to="tel:+91-6369933507"
                        className="link-danger  d-block"
                      >
                        For More Customizations: <br /> +91-6369933507
                      </Link>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                <ul>
                  <h5 className="text-danger mt-3">GPU Benchmarks</h5>
                  <li className="mt-4">
                    3DMark Timespy:{" "}
                    {pc.gpuBrand !== "In-Built"
                      ? pc.benchmarks.gpuBenchmark.timespy
                      : pc.benchmarks.processorBenchmark.timespy}
                  </li>
                  <li className="mt-4">
                    Heaven's Benchmark:{" "}
                    {pc.gpuBrand !== "In-Built"
                      ? pc.benchmarks.gpuBenchmark.heaven
                      : pc.benchmarks.processorBenchmark.heaven}
                  </li>
                  <h5 className="text-danger mt-5">Processor Benchmarks</h5>
                  <li className="mt-4">
                    Cinebench R23 Single Core:{" "}
                    {pc.benchmarks.processorBenchmark.cbsc}
                  </li>
                  <li className="mt-4">
                    Cinebench R23 Multi Core:{" "}
                    {pc.benchmarks.processorBenchmark.cbmc}
                  </li>
                  <li className="mt-4">
                    Geekbench 6 Single Core:{" "}
                    {pc.benchmarks.processorBenchmark.gbsc}
                  </li>
                  <li className="mt-4">
                    Geekbench 6 Multi Core:{" "}
                    {pc.benchmarks.processorBenchmark.gbmc}
                  </li>
                </ul>
                <Col
                  className="text-secondary ms-4 mb-3"
                  style={{ fontSize: "0.7rem" }}
                >
                  *Scores are for reference purpose only*
                </Col>
              </Col>
            </Row>
          </Card>
        </Tab>
        <Tab eventKey={"2"} title="Features" key={"2"}>
          <Card
            className="shadow-sm text-light "
            style={{ backgroundColor: "#151515" }}
          >
            <Row>
              <Col lg={6} md={12}>
                {width > 992 ? (
                  <>
                    <Col className="text-end p-2">
                      <ShareButton
                        url={`https://www.fusiongaming.in${location.pathname}${location.search}`}
                      />
                    </Col>
                    <Row>
                      <Card.Img
                        src={
                          "/cabinet/" +
                          pc.cabinetBrand +
                          "/" +
                          pc.cabinetBrand +
                          " " +
                          pc.cabinetModel +
                          ".webp"
                        }
                        alt="PC"
                        className="img-fluid mt-4"
                      />
                    </Row>
                    <Col
                      className="text-secondary text-center mb-3"
                      style={{ fontSize: "0.7rem" }}
                    >
                      *Image For Reference Only*
                    </Col>
                    <Col className="text-secondary text-center mb-3">
                      <Link
                        to="tel:+91-6369933507"
                        className="link-danger  d-block"
                      >
                        For More Customizations: <br /> +91-6369933507
                      </Link>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col lg={6}>
                <ul>
                  <h5 className="text-danger mt-3">Processor</h5>
                  {pc.features.processor.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                  <h5 className="text-danger mt-4">Chipset</h5>
                  {pc.features.chipset.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                  <h5 className="text-danger mt-4">RAM</h5>
                  {pc.features.ram.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                  <h5 className="text-danger mt-4">GPU</h5>
                  {pc.features.gpu.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                  <h5 className="text-danger mt-4">Cooler</h5>
                  {pc.features.cooler.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                  <h5 className="text-danger mt-4">Storage</h5>
                  {pc.features.primaryStorage.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                  {pc.secondaryStorage !== "Not Available"
                    ? pc.features.secondaryStorage.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))
                    : ""}
                  <h5 className="text-danger mt-4">PSU</h5>
                  {pc.features.psu.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                  <h5 className="text-danger mt-4">Chassis</h5>
                  {pc.features.cabinet.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Card>
        </Tab>
      </Tabs>
      <Col xxl={12} className="text-center text-danger mt-3">
        <p style={{ margin: 0, fontSize: "18px" }}>
          Price:{" "}
          <span
            className="text-secondary"
            style={{
              textDecoration: "line-through",
              fontWeight: "normal",
              marginRight: "8px",
            }}
          >
            ₹{" "}
            {new Intl.NumberFormat("en-IN").format(
              (Math.round(updatedPrice / 500) * 500 - 10).toFixed(0)
            )}
            /-
          </span>
          <span
            style={{
              color: "#dc3545",
              fontWeight: "normal",
              fontSize: "21px",
            }}
          >
            ₹{" "}
            {new Intl.NumberFormat("en-IN").format(
              (
                (Math.round(updatedPrice / 500) * 500 - 10) *
                (1 - discount / 100)
              ).toFixed(0)
            )}
            /-
          </span>
        </p>
        <p
          style={{
            margin: 10,
            fontSize: "15px",
            color: "#fff",
            fontWeight: "500",
          }}
        >
          Save ₹{" "}
          <span style={{ fontSize: "1.3rem" }}>
            {new Intl.NumberFormat("en-IN").format(
              (
                Math.round(updatedPrice / 500) * 500 -
                10 -
                (Math.round(updatedPrice / 500) * 500 - 10) *
                  (1 - discount / 100)
              ).toFixed(0)
            )}
          </span>{" "}
          ({discount}% Off)
        </p>
      </Col>
      <Col xxl={12} className="my-1 text-danger text-center">
        {msg}
      </Col>
      <Col xxl={12} className="my-3 text-center">
        <Button
          className="mb-5"
          variant="danger"
          onClick={(e) => {
            sendToCart(pc);
          }}
          disabled={subLoading}
        >
          Add To Cart
        </Button>
      </Col>
    </Container>
  ) : (
    ""
  );
};

export default PreBuiltViewer;
