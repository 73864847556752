import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import axios from "axios";
import { url, discount } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import PCSpecModal from "../Modals/PCSpecModal";
import {
  FaGamepad,
  FaRobot,
  FaBuilding,
  FaCamera,
  FaChartLine,
} from "react-icons/fa";
import ShareButton from "../Share/ShareButton";
import uniqid from "uniqid";

const PreBuiltSelector = () => {
  const { prebuiltParm, setPrebuiltParm, authContext, user, setUser } =
    useAuth();
  const pbRef = useRef(null);
  const [selectedIcon, setSelectedIcon] = useState(prebuiltParm.useCase);
  const [useCase, setUseCase] = useState(prebuiltParm.useCase);
  const [price, setPrice] = useState(prebuiltParm.price);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [subLoading, setSubLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const [pcs, setPcs] = useState(prebuiltParm.pcs);
  const [pc, setPc] = useState();

  const [showPc, setShowPc] = useState(false);
  const handleClosePc = () => {
    setShowPc(false);
  };

  useEffect(() => {
    if (pcs.length > 0 && pbRef) {
      const offset = 100; // Height of the header (adjust as needed)
      const elementPosition =
        pbRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [pcs.length]);

  const handleSubmit = () => {
    setSubLoading(true);
    setErrMsg("");
    if (!useCase) {
      setErrMsg("Please Select Use Case");
      setTimeout(() => {
        setErrMsg("");
        setSubLoading(false);
      }, 2000);
      return;
    }
    fetchPreBuilt();
  };

  const fetchPreBuilt = async () => {
    setPcs([]);

    try {
      const res = await axios.post(
        url + "/fetchprebuilt",
        { useCase, price },
        { withCredentials: true }
      );
      setPcs(res.data);
      setPrebuiltParm({ useCase, price, pcs: res.data });
      window.scrollTo(0, 600);
      setSubLoading(false);
    } catch (error) {
      console.log(error);
      setSubLoading(false);
    }
  };

  const handleClick = (usage) => {
    setSelectedIcon(usage);
    setUseCase(usage);
  };

  const sendToCart = (prebuilt, index) => {
    setCartLoading(true);

    if (
      user.cartInfo.filter((i) => {
        return i.itemShort === prebuilt.pcShort;
      }).length === 0
    ) {
      if (authContext !== null) {
        addToCart(prebuilt.pcShort, prebuilt.pc, index);
      } else {
        setUser((prevUser) => {
          return {
            ...prevUser,
            cartInfo: [
              ...prevUser.cartInfo, // Spread existing items in cartInfo
              {
                itemid: uniqid(),
                userid: "temp", // Temporary user or update as necessary
                itemType: "PB",
                itemShort: prebuilt.pcShort,
                item: prebuilt.pc,
                qty: 1,
                stock: "Y",
                created: Date.now(),
                checkoutInfo: null,
                checkouttime: null,
                updatedPrice: null,
              },
            ],
          };
        });
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "Item added to cart!" } : item
          )
        );
        setTimeout(() => {
          setPcs((prevPc) =>
            prevPc.map((item, i) =>
              i === index ? { ...item, message: "" } : item
            )
          );
          setCartLoading(false);
        }, 2000);
      }
    } else {
      setPcs((prevPc) =>
        prevPc.map((item, i) =>
          i === index ? { ...item, message: "Item already in cart!" } : item
        )
      );
      setTimeout(() => {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "" } : item
          )
        );
        setCartLoading(false);
      }, 2000);
    }
  };
  const addToCart = async (itemShort, item, index) => {
    try {
      const res = await axios.post(
        url + "/addToCart",
        {
          userid: user.userInfo[0].id,
          item,
          itemType: "PB",
          itemShort,
          qty: 1,
          stock: "Y",
        },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setPcs((prevPc) =>
        prevPc.map((item, i) =>
          i === index ? { ...item, message: "Item added to cart!" } : item
        )
      );
      setTimeout(() => {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "" } : item
          )
        );
        setCartLoading(false);
      }, 2000);
    } catch (err) {
      setCartLoading(false);
      console.log(err);
    }
  };

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  const icons = [
    { useCase: "gaming", icon: <FaGamepad />, label: "Gaming" },
    { useCase: "ai", icon: <FaRobot />, label: "AI/ML" },
    { useCase: "architecture", icon: <FaBuilding />, label: "Architecture" },
    { useCase: "creator", icon: <FaCamera />, label: "Editing" },
    { useCase: "trader", icon: <FaChartLine />, label: "Trading" },
  ];

  return (
    <Container className="my-5">
      <Row className="justify-content-center my-5">
        <Col
          lg={8}
          xs={12}
          className="border border-dark rounded"
          style={{ backgroundColor: "#151515" }}
        >
          <h1 className="my-4 text-center text-danger">Pre-Builts</h1>
          <Row className="mb-3">
            <Col md={12} className="mb-2 text-center">
              <Form.Label className="text-danger">Select Use Case</Form.Label>
              <Row className="text-center">
                {icons.map((item) => (
                  <Col key={item.useCase}>
                    <div
                      onClick={() => handleClick(item.useCase)}
                      style={{
                        cursor: "pointer",
                        color:
                          selectedIcon === item.useCase ? "#dc3545" : "#fff",
                        fontSize: "2rem",
                        margin: "10px 0",
                      }}
                    >
                      {item.icon}
                    </div>
                    <div
                      style={{
                        color:
                          selectedIcon === item.useCase ? "#dc3545" : "#fff",
                        fontSize: "1rem",
                      }}
                    >
                      {item.label}
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="mb-2 mt-4 text-center">
              <Form.Label className="text-danger">Budget</Form.Label>
              <Form.Range
                aria-label="Set Your Budget"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="custom-slider"
                min={30000}
                max={300000}
                step={5000}
              />
              <p className="text-light">
                {"₹ " + new Intl.NumberFormat("en-IN").format(price) + "/-"}
              </p>
            </Col>
          </Row>
          <Row className="text-danger justify-content-center mb-3">
            {errMsg}
          </Row>
          <Row className="justify-content-center mb-5">
            <Col className="d-flex justify-content-center">
              <Button
                variant="danger"
                onClick={handleSubmit}
                disabled={subLoading}
              >
                Find My PC
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row ref={pbRef} className="justify-content-center my-5">
        {pcs.map((pc, i) => (
          <Row
            key={i}
            className="justify-content-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(useCase + "/" + pc.id);
            }}
          >
            <Col
              lg={8}
              className="border mt-5 p-2 border-dark rounded"
              style={{ backgroundColor: "#151515" }}
            >
              <Card border="danger" style={{ backgroundColor: "#151515" }}>
                <Row>
                  <Col md={3} xs={12}>
                    <Card.Img
                      src={
                        "/cabinet/" +
                        JSON.parse(pc.pc).cabinetBrand +
                        "/" +
                        JSON.parse(pc.pc).cabinetBrand +
                        " " +
                        JSON.parse(pc.pc).cabinetModel +
                        ".webp"
                      }
                      alt="PC"
                      className="img-fluid"
                    />
                  </Col>
                  <Col md={9} xs={12}>
                    <Card.Body>
                      <Card.Title className="text-danger mb-2">
                        <Row>
                          <Col className="col-8">{title(pc.updatedPrice)}</Col>
                          <Col className="text-end col-4 px-3">
                            <ShareButton
                              url={`https://www.fusiongaming.in/prebuilts/${useCase}/${pc.id}`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="text-secondary">
                        <small className="text-light">{pc.pcShort}</small>
                      </Card.Text>
                    </Card.Body>
                    <Row className="mb-2">
                      <Col md={12} className="text-danger my-1 ms-2">
                        <h5>
                          <div>
                            <p style={{ margin: 0, fontSize: "18px" }}>
                              Price:{" "}
                              <span
                                className="text-secondary"
                                style={{
                                  textDecoration: "line-through",
                                  fontWeight: "normal",
                                  marginRight: "8px",
                                }}
                              >
                                ₹{" "}
                                {new Intl.NumberFormat("en-IN").format(
                                  (
                                    Math.round(pc.updatedPrice / 500) * 500 -
                                    10
                                  ).toFixed(0)
                                )}
                                /-
                              </span>
                              <span
                                style={{
                                  color: "#dc3545",
                                  fontWeight: "normal",
                                  fontSize: "21px",
                                }}
                              >
                                ₹{" "}
                                {new Intl.NumberFormat("en-IN").format(
                                  (
                                    (Math.round(pc.updatedPrice / 500) * 500 -
                                      10) *
                                    (1 - discount / 100)
                                  ).toFixed(0)
                                )}
                                /-
                              </span>
                            </p>
                            <p
                              style={{
                                margin: 10,
                                fontSize: "15px",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                            >
                              Save ₹{" "}
                              <span style={{ fontSize: "1.3rem" }}>
                                {new Intl.NumberFormat("en-IN").format(
                                  (
                                    Math.round(pc.updatedPrice / 500) * 500 -
                                    10 -
                                    (Math.round(pc.updatedPrice / 500) * 500 -
                                      10) *
                                      (1 - discount / 100)
                                  ).toFixed(0)
                                )}
                              </span>{" "}
                              ({discount}% Off)
                            </p>
                          </div>
                        </h5>
                      </Col>
                      <Col md={12} className="d-flex justify-content-between">
                        <Col className="col-6">
                          <Col xxl={12} className="text-danger ms-3 my-2">
                            {pc.message}
                          </Col>
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              sendToCart(pc, i);
                            }}
                            className="ms-3 mb-2"
                            disabled={cartLoading}
                          >
                            Add To Cart
                          </Button>
                        </Col>
                        <Col className="col-6 text-end">
                          <Col className="text-danger my-2"> </Col>
                          <Button
                            variant="outline-danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowPc(true);
                              setPc(JSON.parse(pc.pc));
                            }}
                            className="mx-4"
                          >
                            PC Info
                          </Button>
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ))}
      </Row>
      {pc ? <PCSpecModal show={showPc} onHide={handleClosePc} pc={pc} /> : ""}
    </Container>
  );
};

export default PreBuiltSelector;
