import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ListGroup } from "react-bootstrap";

const CancellationAndRefundPolicy = () => {
  return (
    <div className="bg-black text-white py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-left">
            <h2
              className="text-center text-danger mb-4"
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              Cancellation and Refund Policy
            </h2>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              This cancellation policy outlines how you can cancel or seek a
              refund for a product/service that you have purchased through
              Fusion Gaming. Under this policy:
            </p>

            <ul
              style={{
                fontSize: "1.1rem",
                paddingLeft: "1.5rem",
                marginBottom: "1.5rem",
                lineHeight: "1.8",
                color: "#fff", // Set bullet point text to white
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              <section className="mb-4">
                <h4 className="mb-2">1. Cancellations:</h4>
                <ul
                  className="list-unstyled"
                  style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
                >
                  <li className="ms-4 mb-3 text-white rounded">
                    Cancellation requests must be made within{" "}
                    <strong className="text-danger">24 hours</strong> of placing
                    the order. Orders cannot be cancelled once the status is
                    updated to "Confirmed" or if the product is "Out for
                    Delivery". Custom-built PCs and other specially configured
                    products are not eligible for cancellations due to their
                    personalized nature.
                  </li>
                </ul>
              </section>

              <section className="mb-4">
                <h4 className="mb-2">2. Refunds for Custom-Built PCs:</h4>
                <ul
                  className="list-unstyled"
                  style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
                >
                  <li className="ms-4 mb-3 text-white rounded">
                    Refunds or replacements for custom-built PCs will only be
                    processed under the following conditions:
                  </li>
                </ul>
                <li className="ms-4 mb-3 text-white rounded">
                  <ListGroup variant="flush">
                    {" "}
                    <span>
                      Defective or Damaged Product: Customers must report
                      manufacturing defects or shipping damage within{" "}
                      <strong className="text-danger">
                        7 days of receipt.
                      </strong>
                    </span>
                  </ListGroup>
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <ListGroup variant="flush">
                    {" "}
                    Refunds/replacements are not granted for reasons like
                    dissatisfaction with performance or changes in personal
                    preference after purchase.{" "}
                  </ListGroup>
                </li>
              </section>

              <section className="mb-4">
                <h4 className="mb-2">3. Damaged or Defective Products:</h4>
                <ul
                  className="list-unstyled"
                  style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
                ></ul>
                <li className="ms-4 mb-3 text-white rounded">
                  <ListGroup variant="flush">
                    {" "}
                    <span>
                      If you receive a damaged or defective item: Notify our
                      customer service team within{" "}
                      <strong className="text-danger">
                        48 hours of receipt.
                      </strong>{" "}
                      Submit proof (e.g., photos of the damage, video of
                      unpacking the product).{" "}
                    </span>
                  </ListGroup>
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <ListGroup variant="flush">
                    {" "}
                    Our team will verify the claim and make a final decision
                    regarding replacement or refund.{" "}
                  </ListGroup>
                </li>
              </section>

              <section className="mb-4">
                <h4 className="mb-2">
                  4. Misrepresentation or Dissatisfaction:
                </h4>
                <ul
                  className="list-unstyled"
                  style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
                ></ul>
                <li className="ms-4 mb-3 text-white rounded">
                  <ListGroup variant="flush">
                    {" "}
                    <span>
                      If the product received is significantly different from
                      what was described (e.g., incorrect specifications or
                      missing components), report it within{" "}
                      <strong className="text-danger">48 hours.</strong>{" "}
                    </span>
                  </ListGroup>
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <ListGroup variant="flush">
                    {" "}
                    Claims based on personal expectations or subjective
                    dissatisfaction are not eligible for refunds unless
                    supported by clear evidence of misrepresentation.{" "}
                  </ListGroup>
                </li>
              </section>

              <section className="mb-4">
                <h4 className="mb-2">5. Warranty Claims:</h4>
                <ul
                  className="list-unstyled"
                  style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
                >
                  <li className="ms-4 mb-3 text-white rounded">
                    For products with a manufacturer warranty, customers can
                    contact the manufacturer directly or us for repair or
                    replacement. Our team is available to assist with this
                    process if needed.
                  </li>
                </ul>
              </section>

              <section className="mb-4">
                <h4 className="mb-2">6. Refund Processing Time:</h4>
                <ul
                  className="list-unstyled"
                  style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
                >
                  <li className="ms-4 mb-3 text-white rounded">
                    <span>
                      If a refund is approved, it will be processed within{" "}
                      <strong className="text-danger">5-7 business days</strong>{" "}
                      after validation. Refunds will be made via the original
                      payment method. Delays may occur due to holidays or
                      incomplete information.
                    </span>
                  </li>
                </ul>
              </section>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CancellationAndRefundPolicy;
