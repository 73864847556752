import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB5TyZCgLVSDv7zneub9o_vyZYHenyRqEQ",
  authDomain: "fusiongaming-in.firebaseapp.com",
  projectId: "fusiongaming-in",
  storageBucket: "fusiongaming-in.firebasestorage.app",
  messagingSenderId: "718486405618",
  appId: "1:718486405618:web:f297b8a17c04ef4acdeb6b",
  measurementId: "G-E59090BDD3",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
export { auth, GoogleAuthProvider, signInWithPopup, analytics };
