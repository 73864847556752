import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";

import { url } from "../../config";
import { validEmail, validName, validPhone } from "../Regex/Regex";

const DatronixFifa = () => {
  const navigate = useNavigate();
  const [subLoading, setSubLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    setSubLoading(true);
    e.preventDefault();
    setError("");
    if (!formData.name || !validName.test(formData.name)) {
      setError("Please enter a valid name!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    if (!formData.phone || !validPhone.test(formData.phone)) {
      setError("Please enter a valid 10 digit phone number!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
      }, 2000);
      return;
    }
    registerUser(formData);
  };

  async function registerUser(data) {
    try {
      const userData = {
        eventName: "Fusion Gaming FIFA Tournament - Datronix 2025",
        eventDate: "01/02/2025",
        eventLocation: "SRM Easwari Engineering College, Ramapuram",
        eventLocationLink: "https://maps.app.goo.gl/2Rf12udjgNEe5fVf8",
        name: data.name,
        number: data.phone,
        email: data.email,
        description: data.description,
      };
      await registerUserInBackend(userData);
      notifyUser("Registered Sucessfully", "/", 2000);
    } catch (error) {
      await handleRegisterError(error);
    }
  }

  async function handleRegisterError(error) {
    console.error("Registration error:", error);
    notifyUser("An error occurred during Registration! Try Again!", null, 0);
  }

  // Helper function to register user in the backend
  async function registerUserInBackend(userData) {
    try {
      await axios.post(`${url}/formData`, userData, {
        withCredentials: true,
      });
    } catch (err) {
      console.error("Error registering user in backend:", err);
      throw new Error("Backend registration failed");
    }
  }

  // Helper function to notify the user and navigate
  function notifyUser(message, redirectPath, timeout = 0) {
    setError(message);
    setShowAlert(true);
    if (timeout > 0) {
      setTimeout(() => {
        setShowAlert(false);
        setSubLoading(false);
        if (redirectPath) navigate(redirectPath);
      }, timeout);
    } else {
      setSubLoading(false);
    }
  }

  return (
    <div className="bg-black text-white py-5 mt-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              Fusion Gaming X Datronix'25
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={5}>
            <h5
              style={{
                fontSize: "1.1rem",
                letterSpacing: "1px",
              }}
              className="text-center text-light mb-4"
            >
              Registration Form for FIFA Tournament
            </h5>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formFirstName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  className="bg-light"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  maxLength={10}
                  name="phone"
                  className="bg-light"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDescription" className="mb-3">
                <Form.Label>
                  Do you have any requirement for a Custom PC?
                  <span className="text-secondary"> (optional)</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Descibe your required spec, usecase, budget, etc."
                  name="description"
                  className="bg-light"
                  maxLength={300}
                  value={formData.description}
                  onChange={handleChange}
                />
              </Form.Group>
              {showAlert && (
                <Row>
                  <Col className="h5 text-center mt-3 text-danger">{error}</Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button
                    variant="danger"
                    size="md"
                    onClick={handleSubmit}
                    disabled={subLoading}
                  >
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
                <Col xxl={12} className="text-center text-secondary mt-3">
                  <small>
                    Please call us at{" "}
                    <Link className="link-danger" to="tel:+91-6369933507">
                      +91-6369933507
                    </Link>{" "}
                    if you face any issues while filling up this form.
                  </small>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DatronixFifa;
