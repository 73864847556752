import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Spinner,
} from "react-bootstrap";
import PCSpecModal from "../Modals/PCSpecModal";
import ShareButton from "../Share/ShareButton";
import { useAuth } from "../Contexts/MyContext";
import { url, discount } from "../../config";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaTag, FaTools, FaAward, FaAnchor } from "react-icons/fa"; // Icon imports

const GamingPCAd = () => {
  const { authContext, user, setUser } = useAuth();
  const [price, setPrice] = useState(100000);
  const navigate = useNavigate();
  const targetRef = useRef(null);

  const [useCase, setUseCase] = useState("gaming");

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [pcs, setPcs] = useState([]);
  const [pc, setPc] = useState();
  const [cartLoading, setCartLoading] = useState(false);

  const [showPc, setShowPc] = useState(false);
  const handleClosePc = () => {
    setShowPc(false);
  };

  const handleSelectUseCase = (uc) => {
    setUseCase(uc);
  };

  useEffect(() => {
    const fetchPreBuilt = async () => {
      setPcs([]);
      try {
        const res = await axios.post(
          url + "/fetchprebuilt",
          { useCase, price },
          { withCredentials: true }
        );
        setPcs([res.data[0]]);
        if (targetRef.current) {
          targetRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchPreBuilt();
  }, [price, useCase]);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const response = await fetch(
          url +
            `/api/maps?endpoint=place/details/json&place_id=ChIJAVTx_3NbUjoRO8m4Xg2B9lw`
        );
        const data = await response.json();
        setReviews(data.result.reviews);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Not able to get reviews");
        setLoading(false);
      }
    };
    fetchReview();
  }, []);

  const sendToCart = (prebuilt, index) => {
    setCartLoading(true);
    if (authContext != null) {
      if (
        user.cartInfo.filter((i) => {
          return i.itemShort === prebuilt.pcShort;
        }).length === 0
      ) {
        addToCart(prebuilt.pcShort, prebuilt.pc, index);
      } else {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "Item already in cart!" } : item
          )
        );
        setTimeout(() => {
          setPcs((prevPc) =>
            prevPc.map((item, i) =>
              i === index ? { ...item, message: "" } : item
            )
          );
          setCartLoading(false);
        }, 2000);
      }
    } else {
      setPcs((prevPc) =>
        prevPc.map((item, i) =>
          i === index
            ? { ...item, message: "Please login to add to cart!" }
            : item
        )
      );
      setTimeout(() => {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "" } : item
          )
        );
        setCartLoading(false);
      }, 2000);
    }
  };
  const addToCart = async (itemShort, item, index) => {
    try {
      const res = await axios.post(
        url + "/addToCart",
        {
          userid: user.userInfo[0].id,
          item,
          itemType: "PB",
          itemShort,
          qty: 1,
          stock: "Y",
        },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setPcs((prevPc) =>
        prevPc.map((item, i) =>
          i === index ? { ...item, message: "Item added to cart!" } : item
        )
      );
      setTimeout(() => {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "" } : item
          )
        );
        setCartLoading(false);
      }, 2000);
    } catch (err) {
      setCartLoading(false);
      console.log(err);
    }
  };

  const getName = (uc) => {
    if (uc === "gaming") {
      return `Gaming`;
    }
    if (uc === "ai") {
      return `AI/ML`;
    }
    if (uc === "trader") {
      return `Trading`;
    }
    if (uc === "creator") {
      return `Editing`;
    }
    if (uc === "architecture") {
      return `Architectural Work`;
    }
  };

  const title = (price) => {
    if (price < 60000) {
      return `Fusion Gaming Elite PC for ${getName(useCase)}`;
    } else if (price > 59999 && price < 120000) {
      return `Fusion Gaming Pro PC for ${getName(useCase)}`;
    } else if (price > 119999) {
      return `Fusion Gaming Master PC for ${getName(useCase)}`;
    }
  };

  const usps = [
    {
      title: " Competitve Pricing ",
      description:
        "Experience the best competitive pricing in the industry, providing exceptional value for your investment.",
      icon: <FaTag size={40} />,
    },
    {
      title: "High Quality",
      description:
        "Access the latest, most reliable components, expertly chosen for top performance and durability in every build.",
      icon: <FaAnchor size={40} />,
    },
    {
      title: "Lifetime Support",
      description:
        "Enjoy free lifetime service support with every purchase for lasting performance and peace of mind.",
      icon: <FaTools size={40} />,
    },
    {
      title: "Authorised Integrators",
      description:
        "We are authorized system Integrators certified by NVIDIA, AMD, and Intel.",
      icon: <FaAward size={40} />,
    },
  ];

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#000" }}>
      {/* First Section */}
      <Container className="mt-3">
        <Row className="align-items-center justify-content-center">
          <Col lg={6} md={12} className="my-3 order-lg-1 order-2">
            <h1
              className="text-danger"
              style={{
                fontSize: "3.5rem",
                lineHeight: "1.2",
              }}
            >
              Peak <br /> Performance <br />a click away!
            </h1>
            <p
              className="border-top text-light pt-2"
              style={{ fontSize: "1.1rem" }}
            >
              The future of computing with precision-engineered PC's.
            </p>
            <p
              className="text-light"
              style={{ fontSize: "1.05rem", marginTop: "2rem" }}
            >
              Built for performance, style, and reliability, our pre-built
              systems deliver everything you need to crush your computing goals.
            </p>
            <Row className="mt-3 d-flex flex-column flex-md-row align-items-start">
              <Col>
                <Button
                  variant="outline-danger"
                  onClick={() => navigate("/config")}
                >
                  Build Your Own PC
                </Button>
                <Button
                  variant="outline-danger"
                  className="mx-3"
                  onClick={() => navigate("/prebuilts")}
                >
                  Pre-Built PCs
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={12} className="text-center my-3 order-lg-2 order-1">
            <img
              src="Untitled-1.webp" // Replace with your actual image URL
              alt="Gaming Setup"
              className="img-fluid"
              style={{ borderRadius: "8px" }}
            />
          </Col>
        </Row>
      </Container>

      {/* Second Section */}
      <div
        style={{
          backgroundColor: "#454545",
          padding: "10px 0",
          marginTop: "20px",
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: "500",
        }}
      >
        <Container className="text-light">
          Quick Shipping | Secured Payments | Manufacturer Warranty | Genuine
          Parts | Satisfaction Guaranteed
        </Container>
      </div>

      {/* Third Section */}
      <Container style={{ marginTop: "40px" }}>
        <Row>
          <Col xs={12} className="text-danger">
            <h2
              style={{
                fontWeight: "bold",
                fontSize: "1.8rem",
                textAlign: "start",
              }}
            >
              Fusion Gaming Pre-Built PCs
            </h2>
            <p
              style={{
                color: "#fff",
                fontSize: "1rem",
                textAlign: "start",
                marginTop: "1rem",
              }}
            >
              We carefully select and combine the best parts to deliver
              exceptional performance while optimizing your investment. <br />{" "}
              It’s not just about building PCs; it’s about getting the best
              possible results for every rupee spent.
            </p>
          </Col>
        </Row>

        {/* Categories */}
        <Row className="justify-content-start mt-4">
          <Col xs="auto" className="mb-3">
            <Button
              value="gaming"
              style={{ width: "135px" }}
              variant={useCase === "gaming" ? "danger" : "light"}
              onClick={() => handleSelectUseCase("gaming")}
            >
              🎮 Gaming
            </Button>
          </Col>
          <Col xs="auto" className="mb-3">
            <Button
              value="ai"
              style={{ width: "135px" }}
              variant={useCase === "ai" ? "danger" : "light"}
              onClick={() => handleSelectUseCase("ai")}
            >
              🤖 AI/ML
            </Button>
          </Col>
          <Col xs="auto" className="mb-3">
            <Button
              value="architecture"
              style={{ width: "135px" }}
              variant={useCase === "architecture" ? "danger" : "light"}
              onClick={() => handleSelectUseCase("architecture")}
            >
              🏛️ Architecture
            </Button>
          </Col>
          <Col xs="auto" className="mb-3">
            <Button
              value="creator"
              style={{ width: "135px" }}
              variant={useCase === "creator" ? "danger" : "light"}
              onClick={() => handleSelectUseCase("creator")}
            >
              🎨 Editing
            </Button>
          </Col>
          <Col xs="auto" className="mb-3">
            <Button
              value="trader"
              style={{ width: "135px" }}
              variant={useCase === "trader" ? "danger" : "light"}
              onClick={() => handleSelectUseCase("trader")}
            >
              📈 Trading
            </Button>
          </Col>
        </Row>

        {/* Price Range Slider */}
        <Row className="mt-4 text-light">
          <Col lg={8} md={10} sm={12} className="mb-2 mt-4 text-center">
            {/* Slider Label */}
            <Form.Label
              className="fs-5"
              style={{
                fontSize: "1rem",
                textAlign: "start",
                marginTop: "1rem",
              }}
            >
              Set your Budget
            </Form.Label>

            {/* Slider Input */}
            <Form.Range
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              className="custom-slider"
              min={30000}
              max={300000}
              step={5000}
            />

            {/* Display the Selected Budget */}
            <p className="text-center text-danger fs-6 mt-3">
              {"₹ " + new Intl.NumberFormat("en-IN").format(price) + "/-"}
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center mb-5">
          {pcs.map((pc, i) => (
            <Row
              key={i}
              className="justify-content-start"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/prebuilts/" + useCase + "/" + pc.id);
              }}
            >
              <Col
                lg={8}
                className="border mt-5 p-2 border-dark rounded"
                style={{ backgroundColor: "#151515" }}
              >
                <Card border="danger" style={{ backgroundColor: "#151515" }}>
                  <Row>
                    <Col md={3} xs={12}>
                      <Card.Img
                        src={
                          "/cabinet/" +
                          JSON.parse(pc.pc).cabinetBrand +
                          "/" +
                          JSON.parse(pc.pc).cabinetBrand +
                          " " +
                          JSON.parse(pc.pc).cabinetModel +
                          ".webp"
                        }
                        alt="PC"
                        className="img-fluid"
                      />
                    </Col>
                    <Col md={9} xs={12}>
                      <Card.Body>
                        <Card.Title className="text-danger mb-2">
                          <Row>
                            <Col className="col-8">
                              {title(pc.updatedPrice)}
                            </Col>
                            <Col className="text-end col-4 px-3">
                              <ShareButton
                                url={`https://www.fusiongaming.in/prebuilts/${useCase}/${pc.id}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </Col>
                          </Row>
                        </Card.Title>
                        <Card.Text className="text-secondary">
                          <small className="text-light">{pc.pcShort}</small>
                        </Card.Text>
                      </Card.Body>
                      <Row className="mb-2">
                        <Col md={12} className="text-danger my-1 ms-2">
                          <h5>
                            <div>
                              <p style={{ margin: 0, fontSize: "18px" }}>
                                Price:{" "}
                                <span
                                  className="text-secondary"
                                  style={{
                                    textDecoration: "line-through",
                                    fontWeight: "normal",
                                    marginRight: "8px",
                                  }}
                                >
                                  ₹{" "}
                                  {new Intl.NumberFormat("en-IN").format(
                                    (
                                      Math.round(pc.updatedPrice / 500) * 500 -
                                      10
                                    ).toFixed(0)
                                  )}
                                  /-
                                </span>
                                <span
                                  style={{
                                    color: "#dc3545",
                                    fontWeight: "normal",
                                    fontSize: "21px",
                                  }}
                                >
                                  ₹{" "}
                                  {new Intl.NumberFormat("en-IN").format(
                                    (
                                      (Math.round(pc.updatedPrice / 500) * 500 -
                                        10) *
                                      (1 - discount / 100)
                                    ).toFixed(0)
                                  )}
                                  /-
                                </span>
                              </p>
                              <p
                                style={{
                                  margin: 10,
                                  fontSize: "15px",
                                  color: "#fff",
                                  fontWeight: "500",
                                }}
                              >
                                Save ₹{" "}
                                <span style={{ fontSize: "1.3rem" }}>
                                  {new Intl.NumberFormat("en-IN").format(
                                    (
                                      Math.round(pc.updatedPrice / 500) * 500 -
                                      10 -
                                      (Math.round(pc.updatedPrice / 500) * 500 -
                                        10) *
                                        (1 - discount / 100)
                                    ).toFixed(0)
                                  )}
                                </span>{" "}
                                ({discount}% Off)
                              </p>
                            </div>
                          </h5>
                        </Col>
                        <Col md={12} className="d-flex justify-content-between">
                          <Col className="col-6">
                            <Col xxl={12} className="text-danger ms-3 my-2">
                              {pc.message}
                            </Col>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                sendToCart(pc, i);
                              }}
                              className="ms-3 mb-2"
                              disabled={cartLoading}
                            >
                              Add To Cart
                            </Button>
                          </Col>
                          <Col className="col-6 text-end">
                            <Col className="text-danger my-2"> </Col>
                            <Button
                              variant="outline-danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowPc(true);
                                setPc(JSON.parse(pc.pc));
                              }}
                              className="mx-4"
                            >
                              PC Info
                            </Button>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ))}
        </Row>
        {pc ? <PCSpecModal show={showPc} onHide={handleClosePc} pc={pc} /> : ""}
      </Container>
      <div
        style={{
          backgroundColor: "#454545",
          padding: "10px 0",
          marginTop: "20px",
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: "500",
        }}
      >
        <Container className="text-light">
          World's First Flawless PC Designing Tool
        </Container>
      </div>
      <Container>
        <Row className="my-4">
          <Col className="text-light">
            <h2 className="text-danger my-3">Customizations Infinite.</h2>
            <Col lg={6}>
              Fusion Gaming's Build Your Own PC is the ultimate solution for
              designing your perfect machine. With its intuitive interface, it
              eliminates compatibility issues and ensures flawless integration
              of every component. Whether you're a novice or a pro, this tool
              gives you complete control over your build, from detailed port
              specifications, warranty to real-world performance benchmarks. Get
              the perfect balance of power, performance, and precision with
              Fusion Gaming, and bring your dream PC to life with confidence.
            </Col>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Button variant="danger" onClick={() => navigate("/config")}>
              Build Yours Now
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <h2 className="text-center text-danger mb-5">
          The Fusion Gaming Difference
        </h2>
        <Row>
          {usps.map((usp, index) => (
            <Col key={index} md={3} xs={6} className="mb-4">
              <Card
                className="usp-card"
                style={{ height: "100%", backgroundColor: "#151515" }}
              >
                <Card.Body className="text-center">
                  <div className="usp-icon mb-3">{usp.icon}</div>
                  <Card.Title className="text-danger">{usp.title}</Card.Title>
                  <Card.Text>{usp.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="free-shipping-banner">
        <Container>
          <Row className="align-items-center">
            <Col md={8} className="text-center">
              <h1 className="text-danger">Free Shipping on all PCs!!</h1>
              <p style={{ fontSize: "1.5rem" }}>
                Every gaming PC delivered directly to your door at no extra
                cost.
              </p>
            </Col>
            <Col md={4} className="image-section">
              <img
                src="shipping-illustration.webp" // Replace with your image path
                alt="Free Shipping"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>
      {error ? (
        ""
      ) : (
        <Container className="my-4">
          <div>
            <h2 className="text-center text-danger my-5">
              This is how our customers feel!
            </h2>
            <div className="scroll-container">
              {reviews.map((review, index) => (
                <Card
                  className="review-card shadow-sm mx-2"
                  key={index}
                  style={{ backgroundColor: "#151515" }}
                >
                  <Card.Img
                    variant="top"
                    src={review.profile_photo_url}
                    alt={`${review.author_name}'s profile`}
                    className="rounded-circle mx-auto mt-3"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>
                      <div className="text-warning">
                        {"★".repeat(review.rating)}
                      </div>
                    </Card.Title>
                    <Card.Text
                      style={{
                        maxHeight: "200px",
                        overflow: "auto",
                        color: "#fff",
                      }}
                    >
                      "{review.text}"
                    </Card.Text>
                    <Card.Footer>
                      <small className="text-secondary">
                        <a
                          href={review.author_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-danger"
                        >
                          {review.author_name}
                        </a>{" "}
                        • {review.relative_time_description}
                      </small>
                    </Card.Footer>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        </Container>
      )}
      <Container
        fluid
        style={{
          background: "#151515",
          color: "#fff",
        }}
      >
        <Row className="align-items-center" style={{ minHeight: "400px" }}>
          {/* Left Column */}
          <Col md={6} className="text-left px-5">
            <h1 className="text-danger mt-4" style={{ fontWeight: "bold" }}>
              Fusion Gaming
            </h1>
            <h2>For Business</h2>
            <p style={{ marginTop: "20px", fontSize: "1.2rem" }}>
              Reliable, high-performance machines built to bring out the best
              from your team.
            </p>
            <Button
              variant="outline-light"
              size="sm"
              style={{ marginTop: "20px", borderRadius: "5px" }}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Unlock Your Team's Potential →
            </Button>
          </Col>

          {/* Right Column */}
          <Col md={6} className="d-flex justify-content-center">
            <img
              src="business.webp"
              alt="Illustration"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GamingPCAd;
