import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaUser,
  FaBox,
  FaMapMarkerAlt,
  FaTag,
  FaSignOutAlt,
} from "react-icons/fa";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import Support from "./Support";
import PCSpecModal from "../Modals/PCSpecModal";
import { BiSupport } from "react-icons/bi";

const MyOrders = () => {
  const navigate = useNavigate();
  const { user, setAuthContext, setUser } = useAuth();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);
  const [issueOrderId, setIssueOrderId] = useState("");
  const [issueOrderIdDisplay, setIssueOrderIdDisplay] = useState("");
  const [loading, setLoading] = useState(true);

  const [pc, setPc] = useState();
  const [showPc, setShowPc] = useState(false);
  const handleClosePc = () => {
    setShowPc(false);
  };

  const [showSupport, setShowSupport] = useState(false);
  const handleCloseSupport = () => {
    setShowSupport(false);
  };

  useEffect(() => {
    const fetchUserData = async (uid) => {
      setLoading(true);
      try {
        const res = await axios.post(
          url + "/fetchAllUserDetails",
          { id: uid },
          { withCredentials: true }
        );
        setUser(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchUserData(user.userInfo[0].id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogout = async (e) => {
    try {
      setLoading(true);
      await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
      setAuthContext(null);
      setUser({ cartInfo: [] });
      setLoading(false);
      navigate("/");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  const OrderCard = ({
    orderId,
    orderIdDisplay,
    orderDate,
    orderStatus,
    deliveredDate,
    quantity,
    orderAddress,
    value,
    imageUrl,
    orderTitle,
    orderDescription,
    discount,
    pc,
  }) => {
    return (
      <Card
        className="mb-3"
        border="danger"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body style={{ fontSize: "0.9rem" }}>
          <Row>
            <Col md={3} className="mb-3">
              {/* Displaying order image */}
              <Card.Img variant="top" src={imageUrl} alt="Order image" />
            </Col>
            <Col md={9}>
              {/* Displaying order details */}
              <Card.Title className="text-light">{orderTitle}</Card.Title>
              <Card.Subtitle className="mb-2 text-secondary">
                Order ID: {orderIdDisplay} <br />
              </Card.Subtitle>
              <Card.Text className="text-light">
                <span className="text-danger">Order Date:</span>{" "}
                {new Date(orderDate).toLocaleDateString()}
                <br />
                <span className="text-danger">Status:</span>{" "}
                {orderStatus === "Delivered"
                  ? orderStatus +
                    " on " +
                    new Date(deliveredDate).toLocaleDateString()
                  : orderStatus}
                <br />
                <span className="text-danger">Quantity:</span> {quantity}
                <br />
                <span className="text-danger">Value:</span> ₹
                {value.toFixed(2) - discount.couponDiscount * -1}{" "}
                {JSON.parse(pc).platform
                  ? "(Saved ₹" +
                    (
                      discount?.couponDiscount * -1 +
                      (discount?.amount /
                        ((discount?.profit / 100) *
                          (1 - discount?.discount / 100))) *
                        (discount?.profit / 100) -
                      discount?.amount
                    ).toFixed(0) +
                    " through offers & coupons)"
                  : ""}{" "}
                <br />
                <span className="text-danger">Spec:</span>{" "}
                <span>{orderDescription}</span>
              </Card.Text>
              {/* Help Button */}
              <Button
                variant="outline-danger"
                onClick={() => {
                  setIssueOrderId(orderId);
                  setIssueOrderIdDisplay(orderIdDisplay);
                  setShowSupport(true);
                }}
              >
                Help & Support
              </Button>
              {JSON.parse(pc).platform ? (
                <Button
                  className="ms-2"
                  variant="outline-danger"
                  onClick={() => {
                    setShowPc(true);
                    setPc(JSON.parse(pc));
                  }}
                >
                  PC Info
                </Button>
              ) : (
                ""
              )}
              <Row>
                <span className="text-secondary mt-3">
                  <FaMapMarkerAlt />
                  {orderAddress}
                </span>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };
  const OffcanvasMenu = () => (
    <Offcanvas
      show={showOffcanvas}
      onHide={handleToggleOffcanvas}
      placement="start"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: "#151515" }}
      >
        <Offcanvas.Title className="text-danger">Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: "#151515" }}>
        <Card
          border="danger"
          className="mb-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Card.Body>
            <Card.Title className="text-danger">Welcome!</Card.Title>
            <Card.Text className="text-light">
              {user.userInfo[0].firstname} <br />
              {user.userInfo[0].email}
            </Card.Text>
          </Card.Body>
        </Card>
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/profile");
              }}
              className="link-danger"
            >
              <FaUser /> My Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/orders");
              }}
              className="link-danger"
            >
              <FaBox /> My Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/addresses");
              }}
              className="link-danger"
            >
              <FaMapMarkerAlt /> My Addresses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/coupons");
              }}
              className="link-danger"
            >
              <FaTag /> My Coupons
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/tickets");
              }}
              className="link-danger"
            >
              <BiSupport /> My Tickets
            </NavLink>
          </NavItem>
          <Nav.Item className="mt-3 mx-3">
            <Button variant="danger" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </Button>
          </Nav.Item>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );

  const Sidebar = () => (
    <>
      {" "}
      <Card
        border="danger"
        className="mb-3"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body>
          <Card.Title className="text-danger">Welcome!</Card.Title>
          <Card.Text className="text-light">
            {user.userInfo[0].firstname} <br />
            {user.userInfo[0].email}
          </Card.Text>
        </Card.Body>
      </Card>
      <Nav defaultActiveKey="/home" className="flex-column">
        <NavItem>
          <NavLink as={Link} to="/profile" className="link-danger">
            <FaUser /> My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/orders" className="link-danger">
            <FaBox /> My Orders
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/addresses" className="link-danger">
            <FaMapMarkerAlt /> My Addresses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/coupons" className="link-danger">
            <FaTag /> My Coupons
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/tickets" className="link-danger">
            <BiSupport /> My Tickets
          </NavLink>
        </NavItem>
        <Nav.Item className="mt-3 mx-3">
          <Button variant="danger" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </Button>
        </Nav.Item>
      </Nav>
    </>
  );

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <Row className="mx-2">
        <Col xs={12} className="d-block d-lg-none">
          <Button
            variant="dark"
            onClick={handleToggleOffcanvas}
            aria-controls="offcanvas-sidebar"
            aria-expanded={showOffcanvas}
            className="mb-3"
          >
            <FaBars /> Menu
          </Button>
        </Col>
        <Col
          xs={12}
          className="d-block d-lg-none border border-dark rounded p-3"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Row>
            <span className="h5 text-danger mb-3">My Orders</span>{" "}
          </Row>
          {user.orderInfo.length !== 0 ? (
            user.orderInfo.map((order, i) => (
              <OrderCard
                key={i}
                orderIdDisplay={"OD-" + order.orderid.toUpperCase()}
                orderId={order.orderid}
                orderDate={order.created}
                orderStatus={order.status}
                deliveredDate={order.updated}
                quantity={order.qty}
                value={JSON.parse(order.discount).amount}
                discount={JSON.parse(order.discount)}
                imageUrl={
                  JSON.parse(order.product).platform
                    ? "/cabinet/" +
                      JSON.parse(order.product).cabinetBrand +
                      "/" +
                      JSON.parse(order.product).cabinetBrand +
                      " " +
                      JSON.parse(order.product).cabinetModel +
                      ".webp"
                    : "/accessories/" + order.productShort + ".webp"
                }
                orderTitle={
                  JSON.parse(order.product).platform
                    ? title(JSON.parse(order.product).price)
                    : order.productShort
                }
                orderDescription={
                  JSON.parse(order.product).platform
                    ? order.productShort
                    : JSON.parse(order.product).item
                }
                orderAddress={order.address}
                pc={order.product}
              />
            ))
          ) : (
            <Row className="text-secondary p-3">No Orders Found!</Row>
          )}
        </Col>
      </Row>
      <Row className="mx-2">
        <Col
          lg={3}
          className="d-none d-lg-block border border-dark rounded p-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Sidebar />
        </Col>
        <Col
          lg={8}
          className="d-none d-lg-block border border-dark rounded p-3 mx-4"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Row>
            <span className="h5 text-danger mb-3">My Orders</span>{" "}
          </Row>
          {user.orderInfo.length !== 0 ? (
            user.orderInfo.map((order, i) => (
              <OrderCard
                key={i}
                orderIdDisplay={"OD-" + order.orderid.toUpperCase()}
                orderId={order.orderid}
                orderDate={order.created}
                orderStatus={order.status}
                deliveredDate={order.updated}
                quantity={order.qty}
                value={JSON.parse(order.discount).amount}
                discount={JSON.parse(order.discount)}
                imageUrl={
                  JSON.parse(order.product).platform
                    ? "/cabinet/" +
                      JSON.parse(order.product).cabinetBrand +
                      "/" +
                      JSON.parse(order.product).cabinetBrand +
                      " " +
                      JSON.parse(order.product).cabinetModel +
                      ".webp"
                    : "/accessories/" + order.productShort + ".webp"
                }
                orderTitle={
                  JSON.parse(order.product).platform
                    ? title(JSON.parse(order.product).price)
                    : order.productShort
                }
                orderDescription={
                  JSON.parse(order.product).platform
                    ? order.productShort
                    : JSON.parse(order.product).item
                }
                orderAddress={order.address}
                pc={order.product}
              />
            ))
          ) : (
            <Row className="text-secondary p-3">No Orders Found!</Row>
          )}
        </Col>
      </Row>
      <OffcanvasMenu />
      <Support
        show={showSupport}
        onHide={handleCloseSupport}
        orderId={issueOrderId}
        orderIdDisplay={issueOrderIdDisplay}
      />
      {pc ? <PCSpecModal show={showPc} onHide={handleClosePc} pc={pc} /> : ""}
    </Container>
  );
};

export default MyOrders;
