import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import { IoIosAdd } from "react-icons/io";
import { FaMinus } from "react-icons/fa6";
import { AiFillDelete } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import PCSpecModal from "../Modals/PCSpecModal";

const Cart = () => {
  const { authContext, user, setUser } = useAuth();
  const [cart, setCart] = useState(user.cartInfo);
  const [total, setTotal] = useState(0);
  const [pcInCart, setPCInCart] = useState("Y");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [updLoading, setUpdLoading] = useState(false);
  const [chkLoading, setChkLoading] = useState(false);
  const prevCartInfoRef = useRef(null);

  const [pc, setPc] = useState();
  const [showPc, setShowPc] = useState(false);
  const handleClosePc = () => {
    setShowPc(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const reload = async () => {
      if (
        user?.cartInfo?.length > 0 &&
        JSON.stringify(user.cartInfo) !==
          JSON.stringify(prevCartInfoRef.current)
      ) {
        await updatePrice(user.cartInfo);
        prevCartInfoRef.current = user.cartInfo;
      } else {
        setLoading(false);
      }
    };
    reload();
  }, [user.cartInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePrice = async (cart) => {
    try {
      const res = await axios.post(
        url + "/updatePrice",
        { cart },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setCart(res.data);
      var tot = 0;
      res.data.forEach((i) => {
        tot = tot + i.updatedPrice * i.qty;
        if (i.itemType === "PC" || i.itemType === "PB") {
          setPCInCart("Y");
        }
      });
      setTotal(tot);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  async function updateQty(itemid, qty) {
    setUpdLoading(true);
    if (authContext === null) {
      await setCart((prevUser) => {
        const findIndex = prevUser.findIndex((item) => item.itemid === itemid);
        const updatedCartInfo = [...prevUser];
        updatedCartInfo[findIndex].qty += qty;
        var tot = 0;
        user.cartInfo.forEach((i) => (tot += i.updatedPrice * i.qty));
        setTotal(tot);
        return updatedCartInfo;
      });
      setUser({ ...user, cartInfo: cart });
      setUpdLoading(false);
      return;
    }
    try {
      const res = await axios.post(
        url + "/updateQty",
        { itemid, qty, userid: user.userInfo[0].id },
        { withCredentials: true }
      );
      var tot = 0;
      res.data.forEach((i) => (tot += i.updatedPrice * i.qty));
      setTotal(tot);
      setUser({ ...user, cartInfo: res.data });
      setCart(res.data);
      setUpdLoading(false);
    } catch (err) {
      setUpdLoading(false);
      console.log(err);
    }
  }

  const handleSubmit = () => {
    setChkLoading(true);
    if (!authContext) {
      setMsg("Kindly login to proceed!");
      setTimeout(() => {
        setMsg("");
        navigate("/login");
        setChkLoading(false);
      }, 2000);
      return;
    }
    if (pcInCart === "Y") {
      checkout();
    } else {
      setMsg(
        "Add-Ons cannot be purchased seperately! Kindly add a PC and proceed to checkout!"
      );
      setTimeout(() => {
        setMsg("");
        setChkLoading(false);
      }, 2000);
    }
  };

  async function checkout() {
    try {
      const res = await axios.post(
        url + "/checkout",
        { uid: user.userInfo[0].id },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setChkLoading(false);
      navigate("/checkout");
    } catch (err) {
      console.log(err);
      setChkLoading(false);
    }
  }

  async function delItem(itemid) {
    setDelLoading(true);
    if (authContext === null) {
      let updatedCartInfo = [];
      const index = cart.findIndex((i) => i.itemid === itemid);
      if (index === cart.length - 1) {
        window.scrollBy(0, -800);
      }
      await setCart((prevUser) => {
        updatedCartInfo = prevUser.filter((item) => item.itemid !== itemid);
        var tot = 0;
        updatedCartInfo.forEach((i) => (tot += i.updatedPrice * i.qty));
        setTotal(tot);
        return updatedCartInfo;
      });
      setUser({ ...user, cartInfo: updatedCartInfo });
      setDelLoading(false);
      return;
    }
    try {
      const res = await axios.post(
        url + "/delItem",
        { itemid, userid: user.userInfo[0].id },
        { withCredentials: true }
      );
      var tot = 0;
      res.data.map((i) => (tot += i.updatedPrice * i.qty));
      setTotal(tot);
      const index = cart.findIndex((i) => i.itemid === itemid);
      if (index === cart.length - 1) {
        window.scrollBy(0, -800);
      }
      setUser({ ...user, cartInfo: res.data });
      setCart(res.data);
      setDelLoading(false);
    } catch (err) {
      console.log(err);
      setDelLoading(false);
    }
  }
  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      {authContext || cart.length === 0 ? (
        ""
      ) : (
        <Row className="my-2 text-light">
          <span>
            <IoIosWarning />{" "}
            <Link className="link-danger" to="/login">
              Login
            </Link>{" "}
            to save your cart for future visits.
          </span>
        </Row>
      )}
      <Row>
        <Col lg={8} md={12}>
          <Container
            className="mb-4 border border-dark rounded"
            style={{
              backgroundColor: "#151515",
            }}
          >
            <h4 className=" mt-2 text-danger">My Cart</h4>
            {cart.length === 0 ? (
              <h4 className="text-secondary mt-5">No Items in Cart!</h4>
            ) : (
              cart.map((i) => (
                <Row key={i.itemid} xs={12} className="p-3">
                  {" "}
                  {/* 1 card per row */}
                  <Card border="danger" style={{ backgroundColor: "#151515" }}>
                    <Row>
                      <Col lg={4} md={5} xs={12}>
                        <Card.Img
                          variant="top"
                          className="mt-2"
                          src={
                            i.itemType === "PC" || i.itemType === "PB"
                              ? "/cabinet/" +
                                JSON.parse(i.item).cabinetBrand +
                                "/" +
                                JSON.parse(i.item).cabinetBrand +
                                " " +
                                JSON.parse(i.item).cabinetModel +
                                ".webp"
                              : "/accessories/" + i.itemShort + ".webp"
                          }
                          alt={i.title}
                        />
                      </Col>
                      <Col lg={8} md={7} xs={12}>
                        <Card.Body>
                          <Card.Title className="text-danger mb-2">
                            {i.itemType === "PC" || i.itemType === "PB"
                              ? title(i.updatedPrice)
                              : i.itemShort}
                          </Card.Title>
                          <Card.Text className="text-secondary">
                            <small className="text-light">
                              {i.itemType === "PC" || i.itemType === "PB"
                                ? i.itemShort
                                : JSON.parse(i.item).item}
                            </small>
                          </Card.Text>
                          <ListGroup variant="flush">
                            <ListGroup.Item
                              style={{ backgroundColor: "#151515" }}
                              className="text-danger border-0"
                            >
                              {i.itemType === "PC" || i.itemType === "PB" ? (
                                <Button
                                  variant="outline-danger"
                                  className="mb-3"
                                  size="sm"
                                  onClick={() => {
                                    setShowPc(true);
                                    setPc(JSON.parse(i.item));
                                  }}
                                >
                                  PC Info
                                </Button>
                              ) : (
                                ""
                              )}
                              <br />
                              Price: ₹
                              {new Intl.NumberFormat("en-IN").format(
                                i.updatedPrice
                              )}
                              /-
                            </ListGroup.Item>
                            <Row>
                              <Col className="col-7">
                                <ListGroup.Item
                                  style={{ backgroundColor: "#151515" }}
                                  className="text-danger border-0"
                                >
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={(e) => {
                                      i.qty > 1
                                        ? updateQty(i.itemid, -1)
                                        : updateQty(i.itemid, 0);
                                    }}
                                    disabled={updLoading}
                                    className="me-2"
                                  >
                                    <FaMinus />
                                  </Button>
                                  {i.qty}
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={(e) => updateQty(i.itemid, 1)}
                                    disabled={updLoading}
                                    className="ms-2"
                                  >
                                    <IoIosAdd />
                                  </Button>
                                </ListGroup.Item>
                              </Col>
                              <Col className="col-5">
                                <Button
                                  variant="danger"
                                  className="ms-3 mt-2"
                                  size="sm"
                                  onClick={(e) => delItem(i.itemid)}
                                  disabled={delLoading}
                                >
                                  <AiFillDelete />
                                </Button>
                              </Col>
                              <Col className="col-12">
                                <ListGroup.Item
                                  style={{ backgroundColor: "#151515" }}
                                  className="text-danger border-0"
                                >
                                  Subtotal:{" "}
                                  <span className="text-light">
                                    ₹
                                    {new Intl.NumberFormat("en-IN").format(
                                      i.updatedPrice * i.qty
                                    )}
                                    /-
                                  </span>
                                </ListGroup.Item>
                              </Col>
                            </Row>
                          </ListGroup>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              ))
            )}
          </Container>
        </Col>
        {cart.length === 0 ? (
          <></>
        ) : (
          <Col lg={4} md={12}>
            <Container
              className="mb-4 border border-dark rounded p-3"
              style={{ minHeight: "20vh", backgroundColor: "#151515" }}
            >
              <h4 className="text-danger">Cart Summary</h4>
              <Card
                className="mt-4"
                border="danger"
                style={{ backgroundColor: "#151515" }}
              >
                <Card.Body className="text-light">
                  <Row className="mt-2 ">
                    <Col>
                      <span className="text-danger">Taxable Value:</span>{" "}
                    </Col>
                    <Col className="ms-3">
                      {" ₹ " +
                        new Intl.NumberFormat("en-IN").format(
                          (total / 1.18).toFixed(0)
                        ) +
                        "/-"}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <span className="text-danger">GST:</span>{" "}
                    </Col>
                    <Col className="ms-3">
                      {" ₹ " +
                        new Intl.NumberFormat("en-IN").format(
                          (total - total / 1.18).toFixed(0)
                        ) +
                        "/-"}
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col>
                      <span className="text-danger">Total:</span>{" "}
                    </Col>
                    <Col className="ms-3">
                      {" ₹ " +
                        new Intl.NumberFormat("en-IN").format(
                          total.toFixed(0)
                        ) +
                        "/-"}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Col className="mt-3 mb-2 text-danger">{msg}</Col>
              <Col>
                <Button
                  variant="danger"
                  onClick={handleSubmit}
                  disabled={chkLoading}
                >
                  Checkout
                </Button>
              </Col>
            </Container>
          </Col>
        )}
      </Row>
      {pc ? <PCSpecModal show={showPc} onHide={handleClosePc} pc={pc} /> : ""}
    </Container>
  );
};

export default Cart;
