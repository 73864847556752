import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import { validPhone } from "../Regex/Regex";

const VerifyPhone = ({ show, onHide }) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { user, setUser } = useAuth();
  let { firstname, lastname, dob, gender, number, email, gst } =
    user?.userInfo[0] || {};

  const [num, setNum] = useState(number);
  const [isResendEnabled, setIsResendEnabled] = useState(false); // Initially false, it will be enabled after 30 seconds
  const [countdown, setCountdown] = useState(30); // Countdown starting at 30 seconds

  useEffect(() => {
    let timer;
    if (!isResendEnabled && countdown > 0) {
      // Start countdown when Resend button is disabled
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            setIsResendEnabled(true); // Enable resend after countdown ends
            clearInterval(timer); // Stop the timer
            return 30; // Reset countdown to 30 seconds
          }
          return prev - 1;
        });
      }, 1000); // Update every second
    }

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [isResendEnabled, countdown]); // Dependency on isResendEnabled and countdown

  async function updateUser() {
    try {
      const res = await axios.post(
        url + "/updateuser",
        {
          userId: user.userInfo[0].id,
          firstName: firstname,
          lastName: lastname,
          number: num,
          email,
          dob,
          gender,
          gst,
          verified: 1,
        },
        { withCredentials: true }
      );
      setUser({ ...user, userInfo: res.data });
      setErrorMessage("OTP Verified");
      setIsLoading(false);
      setTimeout(() => {
        onHide();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  }

  const verify = async () => {
    try {
      const res = await axios.post(
        url + "/verifyotp",
        { phoneNumber: num, otp: otp },
        { withCredentials: true }
      );
      if (res.data.type !== "error") {
        updateUser();
      } else {
        setTimeout(() => {
          setOtp("");
          setErrorMessage("Wrong OTP!!");
          setIsLoading(false);
        }, 1000);
        setTimeout(() => {
          setErrorMessage("");
          setIsLoading(false);
        }, 3000);
      }
    } catch (err) {
      setTimeout(() => {
        setOtp("");
        setErrorMessage("Server Error! Try Later!");
        setIsLoading(false);
      }, 1000);
      setTimeout(() => {
        setErrorMessage("");
        setIsLoading(false);
      }, 3000);
    }
  };

  const send = async () => {
    try {
      await axios.post(
        url + "/sendotp",
        { phoneNumber: num },
        { withCredentials: true }
      );
    } catch (err) {
      setTimeout(() => {
        setOtp("");
        setErrorMessage("Server Error! Not able to send OTP!");
        setIsLoading(false);
      }, 1000);
      setTimeout(() => {
        setErrorMessage("");
        setIsLoading(false);
      }, 3000);
    }
  };

  const resend = async () => {
    try {
      await axios.post(
        url + "/resendotp",
        { phoneNumber: num },
        { withCredentials: true }
      );
    } catch (err) {
      setTimeout(() => {
        setOtp("");
        setErrorMessage("Server Error! Not able to send OTP!");
        setIsLoading(false);
      }, 1000);
      setTimeout(() => {
        setErrorMessage("");
        setIsLoading(false);
      }, 3000);
    }
  };

  const handlePhoneChange = (e) => {
    setNum(e.target.value);
  };

  const handlePhoneSubmit = (e) => {
    setIsResendEnabled(false); // Disable resend button
    setCountdown(30); // Reset countdown
    e.preventDefault();
    if (!validPhone.test(num)) {
      setErrorMessage("Please enter a valid 10 digit phone number!");
      setTimeout(() => {
        setErrorMessage("");
        setIsLoading(false);
      }, 2000);
      return;
    }
    setPhone(false);
    send();
  };

  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (otp === "") {
      setErrorMessage("OTP cannot be blank!");
      setTimeout(() => {
        setErrorMessage("");
        setIsLoading(false);
      }, 2000);
      return;
    } else {
      verify();
    }
  };

  const handleOTPResend = async (e) => {
    setIsResendEnabled(false); // Disable resend button
    setCountdown(30); // Reset countdown
    e.preventDefault();
    await resend(); // Send OTP again
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header style={{ backgroundColor: "#151515" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Modal.Title className="text-danger">Verify Phone Number</Modal.Title>
          <button
            className="custom-close"
            onClick={onHide}
            style={{
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            &times;
          </button>
        </div>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#151515" }}>
        {phone ? (
          <Form onSubmit={handlePhoneSubmit}>
            <Form.Group controlId="phone">
              <Form.Label className="text-light">
                Kindly confirm phone number for OTP verification:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                value={num}
                onChange={handlePhoneChange}
                maxLength={10}
              />
            </Form.Group>

            {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}

            <Row className="mt-3">
              <Col className="col-xs-6 col-sm-3">
                <Button variant="danger" type="submit" disabled={isLoading}>
                  Send OTP
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form onSubmit={handleOTPSubmit}>
            <Form.Group controlId="otp">
              <Form.Label className="text-light">
                Enter the OTP sent to your number:{" "}
                <span className="text-danger">{num}</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={handleOTPChange}
                maxLength={4}
              />
            </Form.Group>

            {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}

            <Row className="mt-3">
              <Col className="col-xs-6 col-sm-3">
                <Button variant="danger" type="submit" disabled={isLoading}>
                  Verify OTP
                </Button>
              </Col>
              <Col className="col-xs-6 col-sm-5">
                <Button
                  variant="danger"
                  onClick={handleOTPResend}
                  disabled={!isResendEnabled}
                >
                  {isResendEnabled ? "Resend" : `Resend in ${countdown}s`}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VerifyPhone;
