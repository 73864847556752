import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import {
  FaDesktop,
  FaKeyboard,
  FaMouse,
  FaHeadphones,
  FaGamepad,
} from "react-icons/fa";
import uniqid from "uniqid";

const Accessories = () => {
  const [selectedIcon, setSelectedIcon] = useState("monitor");
  const { authContext, user, setUser } = useAuth();
  const [accessories, setAccessories] = useState([]);
  const [displayAccessories, setDisplayAccessories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cartLoading, setCartLoading] = useState(false);

  useEffect(() => {
    const fetchAccessories = async () => {
      try {
        const res = await axios.post(url + "/fetchAccessories", {
          withCredentials: true,
        });
        const filtered = res.data.filter((item) => {
          return item.category === "monitor";
        });
        setDisplayAccessories(filtered);
        setAccessories(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchAccessories();
  }, []);

  const handleClick = (category) => {
    setDisplayAccessories([]);
    setSelectedIcon(category);
    const filtered = accessories.filter((item) => {
      return item.category === category;
    });
    setDisplayAccessories(filtered);
  };

  const sendToCart = async (acc, id) => {
    setCartLoading(true);
    if (
      user.cartInfo.filter((i) => {
        return i.itemShort === acc.itemShort;
      }).length === 0
    ) {
      if (authContext != null) {
        addToCart(acc.itemShort, acc, id);
      } else {
        setUser((prevUser) => {
          return {
            ...prevUser,
            cartInfo: [
              ...prevUser.cartInfo, // Spread existing items in cartInfo
              {
                itemid: uniqid(),
                userid: "temp", // Temporary user or update as necessary
                itemType: acc.category,
                itemShort: acc.itemShort,
                item: JSON.stringify(acc),
                qty: 1,
                stock: "Y",
                created: Date.now(),
                checkoutInfo: null,
                checkouttime: null,
                updatedPrice: null,
              },
            ],
          };
        });
        setDisplayAccessories((prevAcc) =>
          prevAcc.map((item, i) =>
            item.id === id ? { ...item, message: "Item added to cart!" } : item
          )
        );
        setTimeout(() => {
          setDisplayAccessories((prevAcc) =>
            prevAcc.map((item, i) =>
              item.id === id ? { ...item, message: "" } : item
            )
          );
          setCartLoading(false);
        }, 2000);
      }
    } else {
      setDisplayAccessories((prevAcc) =>
        prevAcc.map((item, i) =>
          item.id === id ? { ...item, message: "Item already in cart!" } : item
        )
      );
      setTimeout(() => {
        setDisplayAccessories((prevAcc) =>
          prevAcc.map((item, i) =>
            item.id === id ? { ...item, message: "" } : item
          )
        );
        setCartLoading(false);
      }, 2000);
    }
  };

  const addToCart = async (itemShort, item, id) => {
    try {
      const res = await axios.post(
        url + "/addToCart",
        {
          userid: user.userInfo[0].id,
          item: JSON.stringify(item),
          itemType: item.category,
          itemShort,
          qty: 1,
          stock: "Y",
        },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setDisplayAccessories((prevAcc) =>
        prevAcc.map((item, i) =>
          item.id === id ? { ...item, message: "Item added to cart!" } : item
        )
      );
      setTimeout(() => {
        setDisplayAccessories((prevAcc) =>
          prevAcc.map((item, i) =>
            item.id === id ? { ...item, message: "" } : item
          )
        );
        setCartLoading(false);
      }, 2000);
    } catch (err) {
      setCartLoading(false);
      console.log(err);
    }
  };

  const icons = [
    { category: "monitor", icon: <FaDesktop />, label: "Monitor" },
    { category: "keyboard", icon: <FaKeyboard />, label: "Keyboard" },
    { category: "mouse", icon: <FaMouse />, label: "Mouse" },
    { category: "headset", icon: <FaHeadphones />, label: "Headset" },
    { category: "simulator", icon: <FaGamepad />, label: "Simulator" },
  ];

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="my-5">
      <Row className="justify-content-center my-5">
        <Col
          lg={8}
          xs={12}
          className="border border-dark rounded"
          style={{ backgroundColor: "#151515" }}
        >
          <h1 className="my-4 text-center text-danger">Add-Ons</h1>
          <Row className="text-center">
            {icons.map((item) => (
              <Col key={item.category}>
                <div
                  onClick={() => handleClick(item.category)}
                  style={{
                    cursor: "pointer",
                    color: selectedIcon === item.category ? "#dc3545" : "#fff",
                    fontSize: "2rem",
                    margin: "10px 0",
                  }}
                >
                  {item.icon}
                </div>
                <div
                  className="mb-5"
                  style={{
                    color: selectedIcon === item.category ? "#dc3545" : "#fff",
                    fontSize: "1rem",
                  }}
                >
                  {item.label}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {displayAccessories.map((item, i) => (
        <Row key={i} className="justify-content-center">
          <Col
            lg={8}
            className="mt-2 p-3"
            style={{ backgroundColor: "#151515" }}
          >
            <Card border="danger" style={{ backgroundColor: "#151515" }}>
              <Row>
                <Col md={3} xs={12}>
                  <Card.Img
                    src={"/accessories/" + item.itemShort + ".webp"}
                    alt="acc"
                    className="img-fluid"
                  />
                </Col>
                <Col md={9} xs={12}>
                  <Card.Body>
                    <Card.Title className="text-danger mb-2">
                      {item.itemShort}
                    </Card.Title>
                    <Row className="text-secondary">
                      <small
                        className="text-light"
                        style={{ fontSize: "0.95rem" }}
                      >
                        {item.item} <br />
                        Standard {item.warranty} year warranty
                      </small>
                      <Row>
                        <Col
                          className="text-danger mt-3"
                          style={{ fontSize: "1.2rem" }}
                        >
                          {"Price: ₹ " +
                            new Intl.NumberFormat("en-IN").format(
                              item.price.toFixed(0)
                            ) +
                            "/-"}{" "}
                        </Col>
                      </Row>
                    </Row>
                    <Row>
                      <Col className="text-danger mt-1">{item.message}</Col>
                      <Col md={12} className="mt-3">
                        <Button
                          variant="danger"
                          onClick={(e) => sendToCart(item, item.id)}
                          disabled={cartLoading}
                        >
                          Add To Cart
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Accessories;
