import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../Contexts/MyContext";

import { Spinner } from "react-bootstrap";

const RequireAuth = () => {
  const { user, authContext } = useAuth();
  if (!user && authContext) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return authContext ? <Outlet /> : <Navigate to="/login" />;
};

export default RequireAuth;
