import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Tabs,
  Tab,
  Card,
  Container,
  Col,
  Row,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { FaShareNodes } from "react-icons/fa6";
import ShareButton from "../Share/ShareButton";

import { Link } from "react-router-dom";

const PCSpecModal = ({ show, onHide, pc }) => {
  const [key, setKey] = useState("0");
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
  const [shareLoading, setShareLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shareId, setShareId] = useState("");

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const addBuild = async () => {
    setShareLoading(true);
    const itemShort =
      pc.processor +
      "/" +
      pc.chipset +
      "/" +
      pc.ramSize +
      " " +
      pc.ramType +
      (pc.ramRGB === "Y" ? " RGB " : "") +
      "RAM" +
      "/" +
      (pc.gpuBrand !== "In-Built" ? pc.gpuBrand + " " : "") +
      pc.gpuModel +
      "/" +
      pc.cooler +
      "/" +
      pc.psu +
      "/" +
      pc.primaryStorage +
      "" +
      (pc.secondaryStorage !== "Not Available"
        ? "+" + pc.secondaryStorage
        : "") +
      "/" +
      pc.cabinetBrand +
      " " +
      pc.cabinetModel;
    try {
      const res = await axios.post(
        url + "/addBuild",
        {
          itemShort,
          item: JSON.stringify(pc),
          price: pc.price,
        },
        { withCredentials: true }
      );
      setShareId(res.data.id);
    } catch (error) {
      console.log(error);
    } finally {
      setShareLoading(false);
      setIsDropdownOpen(false);
    }
  };
  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Header style={{ backgroundColor: "#151515" }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Modal.Title className="text-danger">PC Specifications</Modal.Title>
          <button
            className="custom-close"
            onClick={(e) => {
              onHide();
            }}
            style={{
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            &times;
          </button>
        </Col>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#151515" }}>
        <Container style={{ backgroundColor: "#151515" }}>
          <Col
            className="h4 mt-3 mb-5 text-center text-danger"
            style={{ backgroundColor: "#151515" }}
          >
            PC Summary
          </Col>
          {width < 992 ? (
            <>
              {!shareId ? (
                <Col className="text-end p-2">
                  <Dropdown
                    show={isDropdownOpen}
                    onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                  >
                    <Dropdown.Toggle
                      variant="outline-danger"
                      id="share-dropdown"
                      onClick={addBuild} // Generate share link on click
                      disabled={shareLoading}
                    >
                      {shareLoading ? "Sharing..." : <FaShareNodes />}
                    </Dropdown.Toggle>
                  </Dropdown>
                </Col>
              ) : (
                <Col className="text-end p-2">
                  <ShareButton
                    onToggle={(isOpen) => setIsDropdownOpen(!isOpen)}
                    open={!isDropdownOpen}
                    url={`https://www.fusiongaming.in/builds/${shareId}`}
                  />
                </Col>
              )}
              <Row>
                <Card.Img
                  src={
                    "/cabinet/" +
                    pc.cabinetBrand +
                    "/" +
                    pc.cabinetBrand +
                    " " +
                    pc.cabinetModel +
                    ".webp"
                  }
                  alt="PC"
                  className="img-fluid"
                />
              </Row>
              <Col
                className="text-secondary text-center mb-3"
                style={{ fontSize: "0.7rem" }}
              >
                *Image For Reference Only*
              </Col>
              <Col className="text-secondary text-center mb-5">
                <Link to="tel:+91-6369933507" className="link-danger  d-block">
                  For More Customizations: <br /> +91-6369933507
                </Link>
              </Col>
            </>
          ) : (
            <></>
          )}
          <Tabs
            id="faq-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-4"
            variant="pills"
            justify
            fill
          >
            <Tab eventKey={"0"} title="Overview" key={"0"}>
              <Card
                className="shadow-sm text-light "
                style={{ backgroundColor: "#151515" }}
              >
                <Row>
                  <Col lg={6} md={12}>
                    {width > 992 ? (
                      <>
                        {!shareId ? (
                          <Col className="text-end p-2">
                            <Dropdown
                              show={isDropdownOpen}
                              onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                            >
                              <Dropdown.Toggle
                                variant="outline-danger"
                                id="share-dropdown"
                                onClick={addBuild} // Generate share link on click
                                disabled={shareLoading}
                              >
                                {shareLoading ? "Sharing..." : <FaShareNodes />}
                              </Dropdown.Toggle>
                            </Dropdown>
                          </Col>
                        ) : (
                          <Col className="text-end p-2">
                            <ShareButton
                              onToggle={(isOpen) => setIsDropdownOpen(!isOpen)}
                              open={!isDropdownOpen}
                              url={`https://www.fusiongaming.in/builds/${shareId}`}
                            />
                          </Col>
                        )}
                        <Row>
                          <Card.Img
                            src={
                              "/cabinet/" +
                              pc.cabinetBrand +
                              "/" +
                              pc.cabinetBrand +
                              " " +
                              pc.cabinetModel +
                              ".webp"
                            }
                            alt="PC"
                            className="img-fluid mt-4"
                          />
                        </Row>
                        <Col
                          className="text-secondary text-center mb-3"
                          style={{ fontSize: "0.7rem" }}
                        >
                          *Image For Reference Only*
                        </Col>
                        <Col className="text-secondary text-center mb-3">
                          <Link
                            to="tel:+91-6369933507"
                            className="link-danger  d-block"
                          >
                            For More Customizations: <br /> +91-6369933507
                          </Link>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col lg={6} md={12}>
                    <Card.Body>
                      <h5 className="text-danger">Specifications</h5>
                      <ul>
                        <li className="mt-4">
                          Processor: {pc.processor} <br />
                          <span className="text-secondary">
                            ({pc.warranty?.processor}-year warranty)
                          </span>
                        </li>
                        <li className="mt-2">
                          Motherboard: {pc.chipset}
                          <br />
                          <span className="text-secondary">
                            ({pc.warranty?.chipset}-year warranty)
                          </span>
                        </li>
                        <li className="mt-2">
                          GPU:{" "}
                          {pc.gpuBrand !== "In-Built"
                            ? pc.gpuBrand + " " + pc.gpuModel
                            : pc.igpu}
                          <br />
                          <span className="text-secondary">
                            ({pc.warranty?.gpu}-year warranty)
                          </span>
                        </li>
                        <li className="mt-2">
                          RAM: {pc.ramSize} {pc.ramType}{" "}
                          {pc.ramRGB === "Y" ? "RGB" : ""}
                          <br />
                          <span className="text-secondary">
                            (
                            {pc.warranty?.ram === 99
                              ? "Limited Lifetime Warranty"
                              : pc.warranty?.ram + "-year warranty"}
                            )
                          </span>
                        </li>
                        <li className="mt-2">
                          Cooler: {pc.cooler}
                          <br />
                          <span className="text-secondary">
                            ({pc.warranty?.cooler}-year warranty)
                          </span>
                        </li>
                        <li className="mt-2">
                          PSU: {pc.psu}
                          <br />
                          <span className="text-secondary">
                            ({pc.warranty?.psu}-year warranty)
                          </span>
                        </li>
                        <li className="mt-2">
                          Primary Storage: {pc.primaryStorage}
                          <br />
                          <span className="text-secondary">
                            ({pc.warranty?.primaryStorage}-year warranty)
                          </span>
                        </li>
                        {pc.secondaryStorage !== "Not Available" ? (
                          <li>
                            Secondary Storage: {pc.secondaryStorage}
                            <br />
                            <span className="text-secondary">
                              ({pc.warranty?.secondaryStorage}-year warranty)
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        <li className="mt-2">
                          Cabinet: {pc.cabinetBrand} {pc.cabinetModel}
                          <br />
                          <span className="text-secondary">
                            ({pc.warranty?.cabinet}-year warranty)
                          </span>
                        </li>
                        <li className="mt-2">
                          Max Power Consumption:{" "}
                          {(
                            (pc.tdp.processorTdp + pc.tdp.gpuTdp) *
                            1.25
                          ).toFixed(0)}
                          W
                        </li>
                      </ul>
                      <h5 className="text-danger">Connectivity</h5>
                      <ul>
                        <li className="mt-4">
                          USB ports:{" "}
                          {pc.connectivity.chipsetConnectivity.usb30 +
                            pc.connectivity.chipsetConnectivity.usb20 +
                            pc.connectivity.chipsetConnectivity.usb32a +
                            pc.connectivity.chipsetConnectivity.usb32c +
                            pc.connectivity.cabinetConnectivity.usb30 +
                            pc.connectivity.cabinetConnectivity.usb20 +
                            pc.connectivity.cabinetConnectivity.usb32a +
                            (pc.connectivity.chipsetConnectivity.tch > 0
                              ? pc.connectivity.cabinetConnectivity.usb32c
                              : 0)}{" "}
                          USB Ports
                          {pc.connectivity.chipsetConnectivity.usb32a +
                            pc.connectivity.cabinetConnectivity.usb32a >
                          0
                            ? " (" +
                              (pc.connectivity.chipsetConnectivity.usb32a +
                                pc.connectivity.cabinetConnectivity.usb32a) +
                              "x USB 3.2 Type-A)"
                            : ""}{" "}
                          {pc.connectivity.cabinetConnectivity.usb30 +
                            pc.connectivity.chipsetConnectivity.usb30 >
                          0
                            ? "(" +
                              (pc.connectivity.cabinetConnectivity.usb30 +
                                pc.connectivity.chipsetConnectivity.usb30) +
                              "x USB 3.0 Type-A)"
                            : ""}{" "}
                          {pc.connectivity.chipsetConnectivity.usb20 +
                            pc.connectivity.cabinetConnectivity.usb20 >
                          0
                            ? "(" +
                              (pc.connectivity.chipsetConnectivity.usb20 +
                                pc.connectivity.cabinetConnectivity.usb20) +
                              "x USB 2.0 Type-A)"
                            : ""}{" "}
                          {pc.connectivity.chipsetConnectivity.usb32c +
                            (pc.connectivity.chipsetConnectivity.tch > 0
                              ? pc.connectivity.cabinetConnectivity.usb32c
                              : 0) >
                          0
                            ? "(" +
                              (pc.connectivity.chipsetConnectivity.usb32c +
                                (pc.connectivity.chipsetConnectivity.tch > 0
                                  ? pc.connectivity.cabinetConnectivity.usb32c
                                  : 0)) +
                              "x USB 3.2 Type-C)"
                            : ""}
                          .
                        </li>
                        <li className="mt-2">
                          Display Support:{" "}
                          {pc.gpuBrand === "In-Built"
                            ? pc.connectivity.chipsetDisplay[0]
                            : pc.connectivity.gpuDisplay[0]}
                        </li>
                        <li className="mt-2">
                          WiFi:{" "}
                          {pc.connectivity.chipsetConnectivity.wifi
                            ? pc.connectivity.chipsetConnectivity.wifi
                            : "No WiFi Support."}
                        </li>
                        <li className="mt-2">
                          Bluetooth:{" "}
                          {pc.connectivity.chipsetConnectivity.bt
                            ? pc.connectivity.chipsetConnectivity.bt
                            : "No Bluetooth Support."}
                        </li>
                        <li className="mt-2">
                          LAN: {pc.connectivity.chipsetConnectivity.lan}
                        </li>
                        <li className="mt-2">
                          Audio: {pc.connectivity.chipsetConnectivity.audio}
                        </li>
                      </ul>
                      <h5 className="text-danger">Expansion availability</h5>
                      <ul>
                        {pc.expansion.chipsetM2Slots -
                          (pc.expansion.psPort === "m.2" ? 1 : 0) -
                          (pc.expansion.ssPort === "m.2" ? 1 : 0) >
                        0 ? (
                          <li className="mt-4">
                            m.2 devices:{" "}
                            {pc.expansion.chipsetM2Slots -
                              (pc.expansion.psPort === "m.2" ? 1 : 0) -
                              (pc.expansion.ssPort === "m.2" ? 1 : 0)}{" "}
                            slots available
                          </li>
                        ) : (
                          ""
                        )}
                        {(pc.expansion.chipsetSataPorts -
                          (pc.expansion.psPort === "sata" ? 1 : 0) -
                          (pc.expansion.ssPort === "sata" ? 1 : 0) >
                        pc.expansion.cabinetSata.sata25 +
                          pc.expansion.cabinetSata.sata35
                          ? pc.expansion.cabinetSata.sata25 +
                            pc.expansion.cabinetSata.sata35
                          : pc.expansion.chipsetSataPorts -
                            (pc.expansion.psPort === "sata" ? 1 : 0) -
                            (pc.expansion.ssPort === "sata" ? 1 : 0)) > 0 ? (
                          <li className="mt-2">
                            SATA devices: Upto{" "}
                            {pc.expansion.chipsetSataPorts -
                              (pc.expansion.psPort === "sata" ? 1 : 0) -
                              (pc.expansion.ssPort === "sata" ? 1 : 0) >
                            pc.expansion.cabinetSata.sata25 +
                              pc.expansion.cabinetSata.sata35
                              ? pc.expansion.cabinetSata.sata25 +
                                pc.expansion.cabinetSata.sata35
                              : pc.expansion.chipsetSataPorts -
                                (pc.expansion.psPort === "sata" ? 1 : 0) -
                                (pc.expansion.ssPort === "sata" ? 1 : 0)}{" "}
                            SATA devices
                          </li>
                        ) : (
                          ""
                        )}
                        {pc.expansion.chispetRamSlots - pc.expansion.ramCount >
                        0 ? (
                          <li className="mt-2">
                            RAM:{" "}
                            {pc.expansion.chispetRamSlots -
                              pc.expansion.ramCount}{" "}
                            slots available
                          </li>
                        ) : (
                          ""
                        )}
                        <li className="mt-2">
                          Power Headroom Available:{" "}
                          {pc.psu.match(/^(\d+)[W]/)[1] -
                            (
                              pc.tdp.processorTdp +
                              pc.tdp.gpuTdp * 1.25
                            ).toFixed(0)}
                          {"W"}
                        </li>
                      </ul>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Tab>
            <Tab eventKey={"1"} title="Benchmarks" key={"1"}>
              <Card
                className="shadow-sm text-light "
                style={{ backgroundColor: "#151515" }}
              >
                <Row>
                  <Col lg={6} md={12}>
                    {width > 992 ? (
                      <>
                        {!shareId ? (
                          <Col className="text-end p-2">
                            <Dropdown
                              show={isDropdownOpen}
                              onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                            >
                              <Dropdown.Toggle
                                variant="outline-danger"
                                id="share-dropdown"
                                onClick={addBuild} // Generate share link on click
                                disabled={shareLoading}
                              >
                                {shareLoading ? "Sharing..." : <FaShareNodes />}
                              </Dropdown.Toggle>
                            </Dropdown>
                          </Col>
                        ) : (
                          <Col className="text-end p-2">
                            <ShareButton
                              onToggle={(isOpen) => setIsDropdownOpen(!isOpen)}
                              open={!isDropdownOpen}
                              url={`https://www.fusiongaming.in/builds/${shareId}`}
                            />
                          </Col>
                        )}
                        <Row>
                          <Card.Img
                            src={
                              "/cabinet/" +
                              pc.cabinetBrand +
                              "/" +
                              pc.cabinetBrand +
                              " " +
                              pc.cabinetModel +
                              ".webp"
                            }
                            alt="PC"
                            className="img-fluid mt-4"
                          />
                        </Row>
                        <Col
                          className="text-secondary text-center mb-3"
                          style={{ fontSize: "0.7rem" }}
                        >
                          *Image For Reference Only*
                        </Col>
                        <Col className="text-secondary text-center mb-3">
                          <Link
                            to="tel:+91-6369933507"
                            className="link-danger  d-block"
                          >
                            For More Customizations: <br /> +91-6369933507
                          </Link>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col>
                    <ul>
                      <h5 className="text-danger mt-3">GPU Benchmarks</h5>
                      <li className="mt-4">
                        3DMark Timespy:{" "}
                        {pc.gpuBrand !== "In-Built"
                          ? pc.benchmarks.gpuBenchmark.timespy
                          : pc.benchmarks.processorBenchmark.timespy}
                      </li>
                      <li className="mt-4">
                        Heaven's Benchmark:{" "}
                        {pc.gpuBrand !== "In-Built"
                          ? pc.benchmarks.gpuBenchmark.heaven
                          : pc.benchmarks.processorBenchmark.heaven}
                      </li>
                      <h5 className="text-danger mt-5">Processor Benchmarks</h5>
                      <li className="mt-4">
                        Cinebench R23 Single Core:{" "}
                        {pc.benchmarks.processorBenchmark.cbsc}
                      </li>
                      <li className="mt-4">
                        Cinebench R23 Multi Core:{" "}
                        {pc.benchmarks.processorBenchmark.cbmc}
                      </li>
                      <li className="mt-4">
                        Geekbench 6 Single Core:{" "}
                        {pc.benchmarks.processorBenchmark.gbsc}
                      </li>
                      <li className="mt-4">
                        Geekbench 6 Multi Core:{" "}
                        {pc.benchmarks.processorBenchmark.gbmc}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card>
              <Col
                className="text-secondary text-center mb-3"
                style={{ fontSize: "0.7rem" }}
              >
                *Scores are for reference purpose only*
              </Col>
            </Tab>
            <Tab eventKey={"2"} title="Features" key={"2"}>
              <Card
                className="shadow-sm text-light "
                style={{ backgroundColor: "#151515" }}
              >
                <Row>
                  <Col lg={6} md={12}>
                    {width > 992 ? (
                      <>
                        {!shareId ? (
                          <Col className="text-end p-2">
                            <Dropdown
                              show={isDropdownOpen}
                              onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                            >
                              <Dropdown.Toggle
                                variant="outline-danger"
                                id="share-dropdown"
                                onClick={addBuild} // Generate share link on click
                                disabled={shareLoading}
                              >
                                {shareLoading ? "Sharing..." : <FaShareNodes />}
                              </Dropdown.Toggle>
                            </Dropdown>
                          </Col>
                        ) : (
                          <Col className="text-end p-2">
                            <ShareButton
                              onToggle={(isOpen) => setIsDropdownOpen(!isOpen)}
                              open={!isDropdownOpen}
                              url={`https://www.fusiongaming.in/builds/${shareId}`}
                            />
                          </Col>
                        )}
                        <Row>
                          <Card.Img
                            src={
                              "/cabinet/" +
                              pc.cabinetBrand +
                              "/" +
                              pc.cabinetBrand +
                              " " +
                              pc.cabinetModel +
                              ".webp"
                            }
                            alt="PC"
                            className="img-fluid mt-4"
                          />
                        </Row>
                        <Col
                          className="text-secondary text-center mb-3"
                          style={{ fontSize: "0.7rem" }}
                        >
                          *Image For Reference Only*
                        </Col>
                        <Col className="text-secondary text-center mb-3">
                          <Link
                            to="tel:+91-6369933507"
                            className="link-danger  d-block"
                          >
                            For More Customizations: <br /> +91-6369933507
                          </Link>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col lg={6}>
                    <ul>
                      <h5 className="text-danger mt-3">Processor</h5>
                      {pc.features.processor.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                      <h5 className="text-danger mt-4">Chipset</h5>
                      {pc.features.chipset.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                      <h5 className="text-danger mt-4">RAM</h5>
                      {pc.features.ram.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                      <h5 className="text-danger mt-4">GPU</h5>
                      {pc.features.gpu.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                      <h5 className="text-danger mt-4">Cooler</h5>
                      {pc.features.cooler.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                      <h5 className="text-danger mt-4">Storage</h5>
                      {pc.features.primaryStorage.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                      {pc.secondaryStorage !== "Not Available"
                        ? pc.features.secondaryStorage.map((feature) => (
                            <li key={feature}>{feature}</li>
                          ))
                        : ""}
                      <h5 className="text-danger mt-4">PSU</h5>
                      {pc.features.psu.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                      <h5 className="text-danger mt-4">Chassis</h5>
                      {pc.features.cabinet.map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#151515" }}>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PCSpecModal;
