import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { url } from "../../config";

const PaymentVerification = () => {
  const { merchantTransactionId } = useParams();
  let navigate = useNavigate();

  let maxRetries = 20;

  useEffect(() => {
    let retryCount = 0;
    let retryTimeout = null;
    const verifyPayment = async (transactionId) => {
      try {
        const res = await axios.get(
          `${url}/payment/validate/${transactionId}`,
          {
            withCredentials: true,
          }
        );
        console.log(res.data.code);
        if (res.data.code === "PAYMENT_SUCCESS") {
          navigate(`/payment/success/${transactionId}`);
        }
      } catch (err) {
        console.error(err);
        const status = err.response?.data?.code;

        if (
          (status === "PAYMENT_PENDING" ||
            status === "INTERNAL_SERVER_ERROR") &&
          retryCount < maxRetries
        ) {
          retryCount++;
          console.log(`Retrying... Attempt ${retryCount}`);
          retryTimeout = setTimeout(() => {
            verifyPayment(transactionId);
          }, 5000);
        } else {
          console.log(
            "Retry limit reached or error occurred. Navigating to failure page."
          );
          navigate(`/payment/fail/${transactionId}`);
        }
      }
    };

    verifyPayment(merchantTransactionId);

    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bg-black">
      <Container className="bg-black pb-5">
        <Container
          className="border border-dark rounded mt-5 p-5 justify-content-center"
          style={{
            backgroundColor: "#151515",
          }}
        >
          <Row className="justify-content-center mt-5">
            <Col className="h1 text-center text-danger col-12">
              Verifying Payment...
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light mt-5 col-10">
              <small>Please do not close this window.</small>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-light col-10">
              <small>You will be redirected automatiaclly.</small>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default PaymentVerification;
