import React from "react";
import { Dropdown } from "react-bootstrap";
import { FaShareNodes } from "react-icons/fa6";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  XIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
import { FiLink } from "react-icons/fi";

const ShareButton = ({ url, onClick, open, onToggle }) => {
  return (
    <Dropdown
      show={open}
      onToggle={onToggle}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}
    >
      <Dropdown.Toggle variant="outline-danger" id="share-dropdown">
        <FaShareNodes />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as="div">
          <FacebookShareButton url={url}>
            <FacebookIcon size={32} round="true" /> Facebook
          </FacebookShareButton>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <TwitterShareButton url={url}>
            <XIcon size={32} round="true" /> X
          </TwitterShareButton>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} round="true" /> LinkedIn
          </LinkedinShareButton>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={32} round="true" /> WhatsApp
          </WhatsappShareButton>
        </Dropdown.Item>
        <Dropdown.Item
          as="div"
          onClick={() => {
            navigator.clipboard.writeText(url.toString());
          }}
        >
          <FiLink size={25} round="true" />
          Copy URL
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShareButton;
