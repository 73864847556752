import { useContext, createContext, useState } from "react";

const MyContext = createContext();

export function useAuth() {
  return useContext(MyContext);
}

export const MyProvider = ({ children }) => {
  const [authContext, setAuthContext] = useState(null);
  const [user, setUser] = useState({ cartInfo: [] });
  const [prebuiltParm, setPrebuiltParm] = useState({
    useCase: "gaming",
    price: 100000,
    pcs: [],
  });
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  return (
    <MyContext.Provider
      value={{
        authContext,
        setAuthContext,
        user,
        setUser,
        email,
        setEmail,
        password,
        setPassword,
        prebuiltParm,
        setPrebuiltParm,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
